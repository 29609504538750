import NetworkTransport from "../entities/transports/NetworkTransport"
import Transport from "../../domain/transport/Transport"
import CarriersMapper from "./CarriersMapper"
import TransportFuelMapper from "./TransportFuelMapper"

export default class TransportsMapper {
  mapNetworkToDomain({
    transport
  }: {
    readonly transport: NetworkTransport
  }): Transport {
    const transportFuelMapper = new TransportFuelMapper()
    const { carrier, transportFuels } = transport

    return {
      id: transport.id,
      name: transport.name,
      stateNumber: transport.stateNumber,
      carrierId: transport.carrierId,
      vinNumber: transport.vinNumber,
      blocked: transport.blocked,
      isDefault: transport.isDefault,
      vehicleCertificateNumber: transport.vehicleCertificateNumber,
      carrier: carrier && new CarriersMapper().mapNetworkToDomain({ carrier }),
      transportFuels: transportFuels && transportFuels.map(transportFuel => {
          return transportFuelMapper.mapNetworkToDomain({ transportFuel })
        }
      )
    }
  }

  mapDomainToNetwork({
    transport
  }: {
    readonly transport: Transport
  }): NetworkTransport {
    const transportFuelMapper = new TransportFuelMapper()
    const { transportFuels } = transport

    return {
      name: transport.name,
      stateNumber: transport.stateNumber,
      carrierId: transport.carrierId,
      vinNumber: transport.vinNumber,
      blocked: transport.blocked,
      isDefault: transport.isDefault,
      vehicleCertificateNumber: transport.vehicleCertificateNumber,
      transportFuels: transportFuels && transportFuels.map(transportFuel => {
          return transportFuelMapper.mapDomainToNetwork({ transportFuel })
        }
      )
    }
  }
}
