import DeviceTypesRepository from "../../domain/repositories/DeviceTypesRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultDeviceTypesRepository from "../../data/repositories/DefaultDeviceTypesRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"

export default interface DeviceTypesCoreDataDiModule {
  provideDeviceTypesRepository(): DeviceTypesRepository
}

export class DefaultDeviceTypesCoreDataDiModule extends DiModule implements DeviceTypesCoreDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideDeviceTypesRepository(): DeviceTypesRepository {
    return this.single(
      DefaultDeviceTypesRepository.name,
      () => new DefaultDeviceTypesRepository({
        deviceTypesNetworkSource: this.appDataDiModule.provideDeviceTypesNetworkSource()
      })
    )
  }
}
