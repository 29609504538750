import NetworkInstallationInstance from "../entities/installation-instance/NetworkInstallationInstance"
import InstallationInstance from "../../domain/installation-instance/InstallationInstance"
import GasStationsMapper from "./GasStationsMapper"
import FuelCompaniesMapper from "./FuelCompaniesMapper"
import DeviceTypesMapper from "./DeviceTypesMapper"

export default class InstallationInstancesMapper {
  mapNetworkToDomain({
    installationInstance
  }: {
    readonly installationInstance: NetworkInstallationInstance
  }): InstallationInstance {
    const { gasStation, deviceType } = installationInstance
    return {
      id: installationInstance.id,
      guid: installationInstance.guid,
      deviceTypeId: installationInstance.deviceTypeId,
      deviceType: deviceType && new DeviceTypesMapper().mapNetworkToDomain({ deviceType }),
      blocked: installationInstance.blocked,
      gasStationId: installationInstance.gasStationId,
      gasStation: gasStation && new GasStationsMapper().mapNetworkToDomain({ gasStation }),
      fuelCompanyId: installationInstance.gasStation?.fuelCompanyId,
      fuelCompany: gasStation && gasStation.fuelCompany && new FuelCompaniesMapper().mapNetworkToDomain({
        fuelCompany: gasStation.fuelCompany,
        creditLimitPeriodTypeSelectOptions: []
      })
    }
  }

  mapDomainToNetwork({
    installationInstance
  }: {
    readonly installationInstance: InstallationInstance
  }): NetworkInstallationInstance {
    return {
      guid: installationInstance.guid,
      deviceTypeId: installationInstance.deviceTypeId,
      blocked: installationInstance.blocked,
      gasStationId: installationInstance.gasStationId
    }
  }
}
