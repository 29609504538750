import GetInstallationInstancesUseCase
  from "../../domain/use-cases/installation-instances/GetInstallationInstancesUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import InstallationInstancesDataDiModule from "./InstallationInstancesDataDiModule"
import CreateInstallationInstanceUseCase
  from "../../domain/use-cases/installation-instances/CreateInstallationInstanceUseCase"
import GetInstallationInstanceUseCase
  from "../../domain/use-cases/installation-instances/GetInstallationInstanceUseCase"
import UpdateInstallationInstanceUseCase
  from "../../domain/use-cases/installation-instances/UpdateInstallationInstanceUseCase"
import GetGasStationsForInstallationInstanceUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForInstallationInstanceUseCase"
import GetFuelCompaniesForInstallationInstancesUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForInstallationInstancesUseCase"

export default interface InstallationInstancesDomainDiModule {
  provideGetInstallationInstancesUseCase(): GetInstallationInstancesUseCase

  provideGetInstallationInstanceUseCase(): GetInstallationInstanceUseCase

  provideCreateInstallationInstanceUseCase(): CreateInstallationInstanceUseCase

  provideUpdateInstallationInstanceUseCase(): UpdateInstallationInstanceUseCase

  provideGetGasStationsForInstallationInstanceUseCase(): GetGasStationsForInstallationInstanceUseCase

  provideGetFuelCompaniesForInstallationInstancesUseCase(): GetFuelCompaniesForInstallationInstancesUseCase
}

export class DefaultInstallationInstancesDomainDiModule
  extends DiModule
  implements InstallationInstancesDomainDiModule {

  private readonly installationInstancesDataDiModule: InstallationInstancesDataDiModule

  constructor(parameters: {
    readonly installationInstancesDataDiModule: InstallationInstancesDataDiModule
  }) {
    super()
    this.installationInstancesDataDiModule = parameters.installationInstancesDataDiModule
  }

  provideGetInstallationInstancesUseCase(): GetInstallationInstancesUseCase {
    return this.single(
      GetInstallationInstancesUseCase.name,
      () => new GetInstallationInstancesUseCase({
        installationInstancesRepository: this.installationInstancesDataDiModule.provideInstallationInstancesRepository()
      })
    )
  }

  provideGetInstallationInstanceUseCase(): GetInstallationInstanceUseCase {
    return this.single(
      GetInstallationInstanceUseCase.name,
      () => new GetInstallationInstanceUseCase({
        installationInstancesRepository: this.installationInstancesDataDiModule.provideInstallationInstancesRepository()
      })
    )
  }

  provideCreateInstallationInstanceUseCase(): CreateInstallationInstanceUseCase {
    return this.single(
      CreateInstallationInstanceUseCase.name,
      () => new CreateInstallationInstanceUseCase({
        installationInstancesRepository: this.installationInstancesDataDiModule.provideInstallationInstancesRepository()
      })
    )
  }

  provideUpdateInstallationInstanceUseCase(): UpdateInstallationInstanceUseCase {
    return this.single(
      UpdateInstallationInstanceUseCase.name,
      () => new UpdateInstallationInstanceUseCase({
        installationInstanceRepository: this.installationInstancesDataDiModule.provideInstallationInstancesRepository()
      })
    )
  }

  provideGetGasStationsForInstallationInstanceUseCase(): GetGasStationsForInstallationInstanceUseCase {
    return this.single(
      GetGasStationsForInstallationInstanceUseCase.name,
      () => new GetGasStationsForInstallationInstanceUseCase({
        gasStationsForInstallationInstancesRepository:
          this.installationInstancesDataDiModule.provideGasStationsForInstallationInstancesRepository()
      })
    )
  }

  provideGetFuelCompaniesForInstallationInstancesUseCase(): GetFuelCompaniesForInstallationInstancesUseCase {
    return this.single(
      GetFuelCompaniesForInstallationInstancesUseCase.name,
      () => new GetFuelCompaniesForInstallationInstancesUseCase({
        fuelCompaniesForInstallationInstancesRepository:
          this.installationInstancesDataDiModule.provideFuelCompaniesForInstallationInstancesRepository()
      })
    )
  }
}
