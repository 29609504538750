import DeviceTypesRepository from "../repositories/DeviceTypesRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import DeviceType from "../../../../core/domain/device-types/DeviceType"

export interface GetDeviceTypesParameters extends GetObjectsParameters {
}

export default class GetDeviceTypesUseCase implements GetObjectsUseCase<DeviceType> {
  private readonly deviceTypesRepository: DeviceTypesRepository

  constructor(parameters: {
    readonly deviceTypesRepository: DeviceTypesRepository
  }) {
    this.deviceTypesRepository = parameters.deviceTypesRepository
  }

  async call(parameters: GetDeviceTypesParameters): Promise<GetObjectsResult<DeviceType>> {
    return this.deviceTypesRepository.getDeviceTypes(parameters)
  }
}
