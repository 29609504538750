import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import InstallationInstancesI18 from "../../i18n/InstallationInstancesI18"
import InstallationInstance from "../../../../core/domain/installation-instance/InstallationInstance"
import isBlank from "../../../../../admin/lib/isBlank"
import InstallationInstancesFilter from "../../../../core/domain/installation-instance/InstallationInstancesFilter"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import GetFuelCompaniesForInstallationInstancesUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForInstallationInstancesUseCase"
import GetGasStationsForInstallationInstanceUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForInstallationInstanceUseCase"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import isPresent from "../../../../../admin/lib/isPresent"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class InstallationInstancesTableProvider
  implements TableProvider<InstallationInstance, InstallationInstancesFilter> {
  private readonly coreI18n: CoreI18n
  private readonly installationInstancesI18: InstallationInstancesI18
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForInstallationInstancesUseCase
  private readonly getGasStationsUseCase: GetGasStationsForInstallationInstanceUseCase

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly installationInstancesI18: InstallationInstancesI18
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForInstallationInstancesUseCase
    readonly getGasStationsUseCase: GetGasStationsForInstallationInstanceUseCase
  }) {
    this.coreI18n = parameters.coreI18n
    this.installationInstancesI18 = parameters.installationInstancesI18
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
  }

  getEntity(): string {
    return Entity.INSTALLATION_INSTANCES
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getTable(): Table<InstallationInstance, InstallationInstancesFilter> {
    const appUrlProvider = new AppUrlProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()
    const installationInstancesTextProvider = this.installationInstancesI18.getTextProvider()

    return new Table<InstallationInstance, InstallationInstancesFilter>({
      title: installationInstancesTextProvider.listTitle(),
      getObjectId: (installationInstance: InstallationInstance) => installationInstance.id!.toString(),
      getRowUrl: (installationInstance: InstallationInstance) => appUrlProvider.buildShowInstallationInstanceUrl({
        installationInstanceId: installationInstance.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewInstallationInstanceUrl(),
      columns: [
        new TableColumn<InstallationInstance>({
          name: "fuelCompany",
          entity: Entity.FUEL_COMPANIES,
          title: installationInstancesTextProvider.fuelCompanyField(),
          createValue: (installationInstance: InstallationInstance) => new TextTableValue({
            value: installationInstance.gasStation?.fuelCompany?.name,
            url: (() => {
              const fuelCompany = installationInstance.gasStation?.fuelCompany
              if (isBlank(fuelCompany)) return null

              return appUrlProvider.buildShowFuelCompanyUrl({
                fuelCompanyId: fuelCompany.id!
              })
            })()
          })
        }),
        new TableColumn<InstallationInstance>({
          name: "gasStation",
          entity: Entity.GAS_STATIONS,
          title: installationInstancesTextProvider.gasStationField(),
          createValue: (installationInstance: InstallationInstance) => new TextTableValue({
            value: installationInstance.gasStation?.name,
            url: (() => {
              const { gasStation } = installationInstance
              if (isBlank(gasStation)) return null

              return appUrlProvider.buildShowGasStationUrl({
                gasStationId: gasStation.id!
              })
            })()
          })
        }),
        new TableColumn<InstallationInstance>({
          name: "guid",
          title: installationInstancesTextProvider.guidField(),
          createValue: (installationInstance: InstallationInstance) => new TextTableValue({
            value: installationInstance.guid
          })
        }),
        new TableColumn<InstallationInstance>({
          name: "deviceType",
          title: installationInstancesTextProvider.deviceTypeField(),
          createValue: (installationInstance: InstallationInstance) => new TextTableValue({
            value: installationInstance.deviceType?.name
          })
        }),
        new TableColumn<InstallationInstance>({
          name: "blocked",
          title: installationInstancesTextProvider.statusField(),
          isSortingAvailable: true,
          reversedSortOrder: true,
          createValue: (installationInstance: InstallationInstance) => {
            const { blocked = false } = installationInstance

            return new TextTableValue({
              value: (blocked ?? false) ?
                installationInstancesTextProvider.statusValueBlocked() :
                installationInstancesTextProvider.statusValueActive(),
              isActive: !installationInstance.blocked
            })
          }
        })
      ],
      filter: new Filter<InstallationInstancesFilter>({
        buildFilterObject: (): InstallationInstancesFilter => ({}),
        fields: [
          new SingleSelectFormField<InstallationInstancesFilter, void, FuelCompany>({
            title: installationInstancesTextProvider.fuelCompanyField(),
            groupName: "",
            entity: Entity.FUEL_COMPANIES,
            getId: () => "fuelCompanyId",
            getValue: (filter: InstallationInstancesFilter) => filter.fuelCompany,
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            setValue: (
              filter: InstallationInstancesFilter,
              fuelCompany: FuelCompany | null
            ): InstallationInstancesFilter => ({
              ...filter,
              fuelCompany,
              fuelCompanyId: fuelCompany?.id,
              gasStation: null,
              gasStationId: null
            }),
            getObjectsUseCase: this.getFuelCompaniesUseCase,
            getOptionId: (optionObject: FuelCompany) => optionObject.id!.toString(),
            getOptionText: (optionObject: FuelCompany) => optionObject.name
          }),
          new SingleSelectFormField<InstallationInstancesFilter, void, GasStation>({
            title: installationInstancesTextProvider.gasStationField(),
            entity: Entity.GAS_STATIONS,
            groupName: "",
            getId: () => "gasStationId",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getValue: (filter: InstallationInstancesFilter) => filter.gasStation,
            setValue: (
              filter: InstallationInstancesFilter,
              gasStation: GasStation | null
            ): InstallationInstancesFilter => ({
              ...filter,
              gasStation,
              gasStationId: gasStation?.id,
              fuelCompany: isPresent(gasStation) ? gasStation.fuelCompany : filter.fuelCompany,
              fuelCompanyId: isPresent(gasStation) ? gasStation.fuelCompany?.id : filter.fuelCompanyId
            }),
            getObjectsUseCase: this.getGasStationsUseCase,
            getOptionId: (gasStation: GasStation) => gasStation.id!.toString(),
            getOptionText: (gasStation: GasStation) => gasStation.name
          })
        ]
      })
    })
  }
}
