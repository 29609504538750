import { Expose, Type } from "class-transformer"
import NetworkTransportFuel from "../transport-fuel/NetworkTransportFuel"
import NetworkCarrier from "../carriers/NetworkCarrier"

export default class NetworkTransport {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly stateNumber?: string | null

  @Expose()
  @Type(() => Number)
  readonly carrierId?: number | null

  @Expose()
  @Type(() => String)
  readonly vinNumber?: string | null

  @Expose()
  @Type(() => String)
  readonly vehicleCertificateNumber?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly blocked?: boolean | null

  @Expose()
  @Type(() => Boolean)
  readonly isDefault?: boolean | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carrier?: NetworkCarrier | null

  @Expose()
  @Type(() => NetworkTransportFuel)
  readonly transportFuels?: NetworkTransportFuel[] | null
}
