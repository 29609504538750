import DeviceTypesRepository from "../../domain/repositories/DeviceTypesRepository"
import { GetDeviceTypesParameters } from "../../domain/use-cases/GetDeviceTypesUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import DeviceTypesNetworkSource from "../../../../core/data/sources/network/DeviceTypesNetworkSource"
import NetworkDeviceTypesRequestFilter
  from "../../../../core/data/entities/device-types/request-queries/NetworkDeviceTypesRequestFilter"
import DeviceType from "../../../../core/domain/device-types/DeviceType"
import DeviceTypesMapper from "../../../../core/data/mappers/DeviceTypesMapper"

export default class DefaultDeviceTypesRepository implements DeviceTypesRepository {
  private readonly deviceTypesNetworkSource: DeviceTypesNetworkSource

  constructor(parameters: {
    readonly deviceTypesNetworkSource: DeviceTypesNetworkSource
  }) {
    this.deviceTypesNetworkSource = parameters.deviceTypesNetworkSource
  }

  async getDeviceTypes({
    query,
    pagination,
    sort
  }: GetDeviceTypesParameters): Promise<GetObjectsResult<DeviceType>> {
    const result = await this.deviceTypesNetworkSource.getDeviceTypes({
      filter: new NetworkDeviceTypesRequestFilter({
        query
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.deviceTypes!.map((deviceType) => {
              return new DeviceTypesMapper().mapNetworkToDomain({ deviceType })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
