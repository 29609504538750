import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import FuelPriceErrorsObjectAttributes from "../../../../../domain/fuel-prices/FuelPriceErrorsObjectAttributes"

export default class NetworkFuelPriceErrorsObjectAttributes implements FuelPriceErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly value?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly startDate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly gasStationIds?: NetworkAttributeError[] | null
}
