import { Expose, Transform, Type } from "class-transformer"
import { Decimal } from "decimal.js"
import transformDecimal from "../../network/transformers/transformDecimal"
import NetworkFuel from "../fuels/NetworkFuel"
import NetworkGasStation from "../gas-stations/NetworkGasStation"
import transformDate from "../../network/transformers/transformDate"
import NetworkFuelCompany from "../fuel-companies/NetworkFuelCompany"

export default class NetworkFuelPrice {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Transform(transformDecimal)
  readonly value?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedValue?: string | null

  @Expose()
  @Transform(transformDate)
  readonly startDate?: Date | null

  @Expose()
  @Type(() => Number)
  readonly fuelId?: number | null

  @Expose()
  @Type(() => NetworkFuel)
  readonly fuel?: NetworkFuel | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyId?: number | null

  @Expose()
  @Type(() => NetworkFuelCompany)
  readonly fuelCompany?: NetworkFuelCompany | null

  @Expose()
  @Type(() => Number)
  readonly gasStationIds?: number[] | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStations?: NetworkGasStation[] | null

  readonly timeZone: string | null | undefined

  constructor(parameters: {
    readonly value: Decimal | null | undefined
    readonly startDate: Date | null | undefined
    readonly fuelId: number | null | undefined
    readonly gasStationIds: number[] | null | undefined
    readonly timeZone: string | null | undefined
  } | null | undefined) {
    this.fuelId = parameters?.fuelId
    this.startDate = parameters?.startDate
    this.gasStationIds = parameters?.gasStationIds
    this.value = parameters?.value
    this.timeZone = parameters?.timeZone
  }
}
