import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkDeviceType from "../NetworkDeviceType"

export default class NetworkDeviceTypesResponseBody {

  @Expose()
  @Type(() => NetworkDeviceType)
  readonly deviceTypes?: NetworkDeviceType[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
