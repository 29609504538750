import NetworkDeviceType from "../entities/device-types/NetworkDeviceType"
import DeviceType from "../../domain/device-types/DeviceType"

export default class DeviceTypesMapper {
  mapNetworkToDomain({
    deviceType
  }: {
    readonly deviceType: NetworkDeviceType
  }): DeviceType {
    return {
      id: deviceType.id,
      name: deviceType.name,
      allowCorrectionByRefueller: deviceType.allowCorrectionByRefueller,
      autoGenerateInstallationInstanceGuid: deviceType.autoGenerateInstallationInstanceGuid
    }
  }
}
