import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStationsForFuelPricesRepository from "../../repositories/GasStationsForFuelPricesRepository"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationsFilter from "../../../../../core/domain/gas-stations/GasStationsFilter"

export interface GetGasStationsForFuelPriceParameters extends GetObjectsParameters<GasStationsFilter> {
}

export default class GetGasStationsForFuelPriceUseCase implements GetObjectsUseCase<GasStation, GasStationsFilter> {
  private readonly gasStationsForFuelPricesRepository: GasStationsForFuelPricesRepository

  constructor(parameters: {
    readonly gasStationsForFuelPricesRepository: GasStationsForFuelPricesRepository
  }) {
    this.gasStationsForFuelPricesRepository = parameters.gasStationsForFuelPricesRepository
  }

  async call(parameters: GetGasStationsForFuelPriceParameters): Promise<GetObjectsResult<GasStation>> {
    return this.gasStationsForFuelPricesRepository.getGasStations(parameters)
  }
}
