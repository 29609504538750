import { Expose, Type } from "class-transformer"

export default class NetworkDeviceType {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly autoGenerateInstallationInstanceGuid?: boolean | null

  @Expose()
  @Type(() => Boolean)
  readonly allowCorrectionByRefueller?: boolean | null
}
