import { Expose, Type } from "class-transformer"
import NetworkGasStation from "../gas-stations/NetworkGasStation"
import NetworkDeviceType from "../device-types/NetworkDeviceType"

export default class NetworkInstallationInstance {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly guid?: string | null

  @Expose()
  @Type(() => Number)
  readonly deviceTypeId?: number | null

  @Expose()
  @Type(() => Boolean)
  readonly blocked?: boolean | null

  @Expose()
  @Type(() => Number)
  readonly gasStationId?: number | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStation?: NetworkGasStation | null

  @Expose()
  @Type(() => NetworkDeviceType)
  readonly deviceType?: NetworkDeviceType | null
}
