import InstallationInstancesTextProvider from "../InstallationInstancesTextProvider"

export default class DefaultRuInstallationInstancesTextProvider implements InstallationInstancesTextProvider {
  fuelCompanyFilter(): string {
    return "Топливная компания"
  }

  blockedField(): string {
    return "Блокировка"
  }

  guidField(): string {
    return "Идентификатор установки"
  }

  deviceTypeField(): string {
    return "Тип устройства"
  }

  existObjectTitle(): string {
    return "Устройство"
  }

  listTitle(): string {
    return "Устройства"
  }

  fuelCompanyField(): string {
    return "Топливная компания"
  }

  gasStationField(): string {
    return "АЗС"
  }

  newObjectTitle(): string {
    return "Новое устройство"
  }

  statusField(): string {
    return "Статус"
  }

  statusValueActive(): string {
    return "Активен"
  }

  statusValueBlocked(): string {
    return "Заблокирован"
  }
}
