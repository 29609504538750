import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import GetObjectsUseCase, { GetObjectsResult } from "../../../../domain/use-cases/objects/GetObjectsUseCase"
import Page from "../../../../../../core/domain/entities/pages/Page"
import isBlank from "../../../../../../lib/isBlank"
import SelectOption from "../../../../../../lib/picker/SelectOption"
import assertNever from "../../../../../../lib/assertNever"
import isPresent from "../../../../../../lib/isPresent"

const queryInputDebounceTimeoutInMilliseconds = 300

export default class MultiSelectFormField<DomainObject, ErrorsObject, OptionObject, OptionObjectsFilter = never>
  extends FormField<DomainObject, ErrorsObject> {

  private readonly getObjectsUseCase: GetObjectsUseCase<OptionObject, OptionObjectsFilter>
  private readonly getValue: (object: DomainObject) => OptionObject[] | null | undefined
  private readonly setValue: (object: DomainObject, value: OptionObject[] | null) => DomainObject
  private readonly getOptionId: (optionObject: OptionObject) => string
  private readonly getOptionText: (optionObject: OptionObject) => string | null | undefined
  private readonly getOptionObjectsFilter?: (object: DomainObject) => OptionObjectsFilter | undefined | null
  private shouldReloadOnChangeValue: boolean
  private isLoadingByStateId: { [key: string]: boolean }
  private optionObjectsByStateId: { [key: string]: OptionObject[] }
  private queryByStateId: { [key: string]: string | null | undefined }
  private queryInputTimeoutByStateId: { [key: string]: NodeJS.Timeout }
  private lastLoadingTimestampByStateId: { [key: string]: number }
  private pageByStateId: { [key: string]: Page | undefined }

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly shouldReloadOnChangeValue?: boolean
    readonly getObjectsUseCase: GetObjectsUseCase<OptionObject>
    readonly getValue: (object: DomainObject) => OptionObject[] | null | undefined
    readonly setValue: (object: DomainObject, value: OptionObject[] | null) => DomainObject
    readonly getOptionId: (optionObject: OptionObject) => string
    readonly getOptionText: (optionObject: OptionObject) => string | null | undefined
    readonly getOptionObjectsFilter?: (object: DomainObject) => OptionObjectsFilter | undefined | null
  }) {
    super(parameters)
    this.shouldReloadOnChangeValue = parameters.shouldReloadOnChangeValue ?? false
    this.getObjectsUseCase = parameters.getObjectsUseCase
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
    this.getOptionId = parameters.getOptionId
    this.getOptionText = parameters.getOptionText
    this.getOptionObjectsFilter = parameters.getOptionObjectsFilter
    this.isLoadingByStateId = {}
    this.optionObjectsByStateId = {}
    this.queryByStateId = {}
    this.queryInputTimeoutByStateId = {}
    this.lastLoadingTimestampByStateId = {}
    this.pageByStateId = {}
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    const stateId: string = this.getId(object)
    const selectedValues: OptionObject[] | null | undefined = this.getValue(object)
    const selectedOptions: SelectOption<OptionObject>[] = this.createOptions(selectedValues)
    const isLoading: boolean = this.isLoadingByStateId[stateId] ?? false
    const query: string | null | undefined = this.queryByStateId[stateId]
    const page: Page | undefined = this.pageByStateId[stateId]
    const options: SelectOption<OptionObject>[] = this.createOptions(this.optionObjectsByStateId[stateId])

    const loadAndShowOptions = async(): Promise<void> => {
      const timestamp: number = new Date().getTime()
      this.lastLoadingTimestampByStateId = { ...this.lastLoadingTimestampByStateId, [stateId]: timestamp }
      this.optionObjectsByStateId = { ...this.optionObjectsByStateId, [stateId]: [] }
      this.pageByStateId = { ...this.pageByStateId, [stateId]: undefined }
      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: true }
      this.setAndShowLoadedObjectViewState()

      const result: GetObjectsResult<OptionObject> = await this.getObjectsUseCase.call({
        query: this.queryByStateId[stateId],
        filter: (() => {
          if (isBlank(this.getOptionObjectsFilter)) return {} as OptionObjectsFilter

          return this.getOptionObjectsFilter(object)
        })()
      })

      const isLastLoading: boolean = timestamp === this.lastLoadingTimestampByStateId[stateId]

      if (!isLastLoading) {
        return
      }

      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: false }

      switch (result.type) {
        case "error":
          // TODO: show error
          break
        case "failure":
          // TODO: show failure
          break
        case "success":
          this.optionObjectsByStateId = { ...this.optionObjectsByStateId, [stateId]: result.data.objects }
          this.pageByStateId = { ...this.pageByStateId, [stateId]: result.data.page }
          break
      }

      this.setAndShowLoadedObjectViewState()
    }

    const loadNextOptionsPageAndShowOptions = async(): Promise<void> => {
      const timestamp: number = new Date().getTime()
      this.lastLoadingTimestampByStateId = { ...this.lastLoadingTimestampByStateId, [stateId]: timestamp }
      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: true }
      this.setAndShowLoadedObjectViewState()

      const optionObjects: OptionObject[] = this.optionObjectsByStateId[stateId] ?? []
      const lastOptionObjectIndex: number = optionObjects.length - 1
      const lastOptionObject: OptionObject = optionObjects[lastOptionObjectIndex]

      const result: GetObjectsResult<OptionObject> = await this.getObjectsUseCase.call({
        query: this.queryByStateId[stateId],
        filter: (() => {
          if (isBlank(this.getOptionObjectsFilter)) return {} as OptionObjectsFilter

          return this.getOptionObjectsFilter(object)
        })(),
        pagination: {
          id: this.getOptionId(lastOptionObject)
        }
      })

      const isLastLoading: boolean = timestamp === this.lastLoadingTimestampByStateId[stateId]

      if (!isLastLoading) {
        return
      }

      this.isLoadingByStateId = { ...this.isLoadingByStateId, [stateId]: false }

      switch (result.type) {
        case "error":
          // TODO: show error
          break
        case "failure":
          // TODO: show failure
          break
        case "success":
          this.optionObjectsByStateId = {
            ...this.optionObjectsByStateId,
            [stateId]: [...optionObjects, ...result.data.objects]
          }
          this.pageByStateId = { ...this.pageByStateId, [stateId]: result.data.page }
          break
      }

      this.setAndShowLoadedObjectViewState()
    }

    return new MultiSelectFormFieldViewState<OptionObject>({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      selectedOptions,
      isLoading,
      options,
      query,
      page,
      onSelectedItemClicked: (selectOption: SelectOption<OptionObject>) => {
        const currentSelectedValues = selectedValues ?? []
        const filteredSelectedValues = currentSelectedValues.filter((selectedValue) => {
          return selectedValue !== selectOption.originalObject
        })

        this.setObject(this.setValue(object, filteredSelectedValues))

        this.setAndShowLoadedObjectViewState()
        this.cacheObject && this.cacheObject(object)
      },
      onSelect: (value: SelectOption<OptionObject>) => {
        const originalObject = value.originalObject
        if (!originalObject) return

        const currentSelectedOptions = selectedOptions ?? []
        const existedElementIndex = currentSelectedOptions.findIndex((selectedOption) => {
          return selectedOption.id === value?.id
        })

        const previousFilterValue = isPresent(this.getOptionObjectsFilter) ?
          JSON.stringify(this.getOptionObjectsFilter(object)) :
          ""

        const currentSelectedValues = (() => {
          const values = selectedValues ?? []
          if (existedElementIndex < 0) {
            values.push(originalObject)
          } else {
            values.splice(existedElementIndex, 1)
          }

          return values
        })()

        this.setObject(this.setValue(object, currentSelectedValues))
        const currentFilterValue = isPresent(this.getOptionObjectsFilter) ?
          JSON.stringify(this.getOptionObjectsFilter(object)) :
          ""

        if (this.shouldReloadOnChangeValue && previousFilterValue !== currentFilterValue) {
          loadAndShowOptions().then()
        }

        this.setAndShowLoadedObjectViewState()
      },
      onOpened: () => {
        loadAndShowOptions().then()
      },
      onQueryChanged: (query: string) => {
        this.queryByStateId = { ...this.queryByStateId, [stateId]: query }
        this.setAndShowLoadedObjectViewState()

        clearTimeout(this.queryInputTimeoutByStateId[stateId])

        this.queryInputTimeoutByStateId = {
          ...this.queryInputTimeoutByStateId,
          [stateId]: setTimeout(() => {
            loadAndShowOptions().then()
          }, queryInputDebounceTimeoutInMilliseconds)
        }
      },
      onSearchRequested: () => {
        loadAndShowOptions().then()
      },
      onNextPageRequested: () => {
        loadNextOptionsPageAndShowOptions().then()
      }
    })
  }

  serializeValue(object: DomainObject): string | null | undefined {
    const value: OptionObject[] | null | undefined = this.getValue(object)
    return value
      ?.map((optionObject: OptionObject) => this.getOptionId(optionObject))
      .join(",")
  }

  async init({
    object,
    serializedValue
  }: {
    readonly object: DomainObject
    readonly serializedValue: string | null | undefined
  }): Promise<void> {
    if (serializedValue === null || serializedValue === undefined) {
      return
    }

    const optionIds: string[] = serializedValue.split(",")

    const result: GetObjectsResult<OptionObject> = await this.getObjectsUseCase.call({
      ids: optionIds
    })

    switch (result.type) {
      case "error":
        // TODO: show error
        break
      case "failure":
        // TODO: show failure
        break
      case "success": {
        const optionObjects: OptionObject[] = result.data.objects

        if (optionObjects.length > 0) {
          this.setObject(this.setValue(object, optionObjects))
        }

        break
      }
      default:
        assertNever(result)
    }
  }

  private createOptions(selectedValues: OptionObject[] | null | undefined): SelectOption<OptionObject>[] {
    if (isBlank(selectedValues)) return []

    return selectedValues.map((selectedValue) => this.createOption(selectedValue))
  }

  private createOption(optionObject: OptionObject): SelectOption<OptionObject> {
    return {
      id: this.getOptionId(optionObject),
      text: this.getOptionText(optionObject),
      originalObject: optionObject
    }
  }
}

export class MultiSelectFormFieldViewState<OptionObject> extends FormFieldViewState {
  readonly selectedOptions: SelectOption<OptionObject>[]
  readonly isLoading: boolean
  readonly options: SelectOption<OptionObject>[]
  readonly query?: string | null
  readonly page?: Page
  readonly onSelect: (value: SelectOption<OptionObject>) => void
  readonly onOpened: () => void
  readonly onSearchRequested: () => void
  readonly onQueryChanged: (query: string) => void
  readonly onNextPageRequested: () => void
  readonly onSelectedItemClicked: (selectOption: SelectOption<OptionObject>) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onSelect: (value: SelectOption<OptionObject>) => void
    readonly onOpened: () => void
    readonly selectedOptions: SelectOption<OptionObject>[]
    readonly isLoading: boolean
    readonly options: SelectOption<OptionObject>[]
    readonly query?: string | null
    readonly page?: Page
    readonly onSearchRequested: () => void
    readonly onQueryChanged: (query: string) => void
    readonly onNextPageRequested: () => void
    readonly onSelectedItemClicked: (selectOption: SelectOption<OptionObject>) => void
  }) {
    super(parameters)
    this.onSelect = parameters.onSelect
    this.onOpened = parameters.onOpened
    this.onSearchRequested = parameters.onSearchRequested
    this.onQueryChanged = parameters.onQueryChanged
    this.onNextPageRequested = parameters.onNextPageRequested
    this.onSelectedItemClicked = parameters.onSelectedItemClicked
    this.selectedOptions = parameters.selectedOptions
    this.isLoading = parameters.isLoading
    this.options = parameters.options
    this.query = parameters.query
    this.page = parameters.page
  }

  hasValue(): boolean {
    return this.selectedOptions.length > 0
  }
}
