import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import InstallationInstanceErrorsObjectAttributes
  from "../../../../../domain/installation-instance/InstallationInstanceErrorsObjectAttributes"

export default class NetworkInstallationInstanceErrorsObjectAttributes
  implements InstallationInstanceErrorsObjectAttributes {

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly guid?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly deviceTypeId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly gasStationId?: NetworkAttributeError[] | null
}
