import AdminRootDiComponent from "../../admin/features/root/di/components/RootDiComponent"
import AuthenticationDiComponent from "../../admin/features/authentication/di/components/AuthenticationDiComponent"
import AdminCoreDomainDiModule, {
  DefaultCoreDomainDiModule as DefaultAdminCoreDomainDiModule
} from "../../admin/core/di/modules/CoreDomainDiModule"
import { DefaultCoreDataDiModule as DefaultAdminCoreDataDiModule } from "../../admin/core/di/modules/CoreDataDiModule"
import DefaultCoreNetworkDiModule from "../core/di/modules/DefaultCoreNetworkDiModule"
import {
  DefaultAuthenticationPresentationDiModule
} from "../../admin/features/authentication/di/modules/AuthenticationPresentationDiModule"
import {
  DefaultAuthenticationI18nDiModule
} from "../../admin/features/authentication/di/modules/AuthenticationI18nDiModule"
import DefaultRuAuthenticationTextProvider
  from "../../admin/features/authentication/i18n/authentication-text-providers/DefaultRuAuthenticationTextProvider"
import AdminCoreDiComponent from "../../admin/core/di/components/CoreDiComponent"
import { DefaultCoreI18nDiModule as DefaultAdminCoreI18nDiModule } from "../../admin/core/di/modules/CoreI18nDiModule"
import DefaultRuAdminCoreTextProvider from "../../admin/core/i18n/core-text-providers/DefaultRuCoreTextProvider"
import React, { ReactElement } from "react"
import CoreI18n from "../../admin/core/i18n/CoreI18n"
import AuthenticationI18n from "../../admin/features/authentication/i18n/AuthenticationI18n"
import AdminCoreLocalDiModule, {
  DefaultCoreLocalDiModule as DefaultAdminCoreLocalDiModule
} from "../../admin/core/di/modules/CoreLocalDiModule"
import AppI18nDiModule, { DefaultAppI18nDiModule } from "../core/di/modules/AppI18nDiModule"
import DefaultRuAppTextProvider from "../core/i18n/core-text-providers/DefaultRuAppTextProvider"
import ObjectsPresentationDiModule, {
  DefaultObjectsPresentationDiModule
} from "../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import { AdminObjectsDomainDiModule } from "../../admin/features/objects/di/modules/ObjectsDomainDiModule"
import { DefaultObjectsDataDiModule } from "../../admin/features/objects/di/modules/ObjectsDataDiModule"
import { DefaultRootDomainDiModule } from "../../admin/features/root/di/modules/RootDomainDiModule"
import DefaultRootDataDiModule from "../features/root/di/modules/DefaultRootDataDiModule"
import AppNetworkDiModule, { DefaultAppNetworkDiModule } from "../core/di/modules/AppNetworkDiModule"
import { DefaultRootPresentationDiModule } from "../features/root/di/modules/DefaultRootPresentationDiModule"
import CarriersDiComponent from "../features/carriers/di/components/CarriersDiComponent"
import { DefaultCarriersPresentationDiModule } from "../features/carriers/di/modules/CarriersPresentationDiModule"
import { DefaultCarriersDomainDiModule } from "../features/carriers/di/modules/CarriersDomainDiModule"
import { DefaultCarriersDataDiModule } from "../features/carriers/di/modules/CarriersDataDiModule"
import { DefaultAppDataDiModule } from "../core/di/modules/AppDataDiModule"
import CarriersI18nDiModule, { DefaultCarriersI18nDiModule } from "../features/carriers/di/modules/CarriersI18nDiModule"
import DefaultRuCarriersTextProvider from "../features/carriers/i18n/core-text-providers/DefaultRuCarriersTextProvider"
import FuelCompaniesDiComponent from "../features/fuel-companies/di/components/FuelCompaniesDiComponent"
import FuelCompaniesI18nDiModule, {
  DefaultFuelCompaniesI18nDiModule
} from "../features/fuel-companies/di/modules/FuelCompaniesI18nDiModule"
import DefaultRuFuelCompaniesTextProvider
  from "../features/fuel-companies/i18n/core-text-providers/DefaultRuFuelCompaniesTextProvider"
import {
  DefaultFuelCompaniesPresentationDiModule
} from "../features/fuel-companies/di/modules/FuelCompaniesPresentationDiModule"
import { DefaultFuelCompaniesDomainDiModule } from "../features/fuel-companies/di/modules/FuelCompaniesDomainDiModule"
import { DefaultFuelCompaniesDataDiModule } from "../features/fuel-companies/di/modules/FuelCompaniesDataDiModule"
import NotFoundDiComponent from "../../admin/features/not-found/di/components/NotFoundDiComponent"
import NotFoundI18nDiModule, {
  DefaultNotFoundI18nDiModule
} from "../../admin/features/not-found/di/modules/NotFoundI18nDiModule"
import DefaultRuNotFoundTextProvider
  from "../../admin/features/not-found/i18n/not-found-text-providers/DefaultRuNotFoundTextProvider"
import {
  DefaultNotFoundPresentationDiModule
} from "../../admin/features/not-found/di/modules/NotFoundPresentationDiModule"
import DefaultNotFoundThemeDiModule from "../features/not-found/DefaultNotFoundThemeDiModule"
import GasStationsDiComponent from "../features/gas-stations/di/components/GasStationsDiComponent"
import {
  DefaultGasStationsPresentationDiModule
} from "../features/gas-stations/di/modules/GasStationsPresentationDiModule"
import { DefaultGasStationsDomainDiModule } from "../features/gas-stations/di/modules/GasStationsDomainDiModule"
import GasStationsI18nDiModule, {
  DefaultGasStationsI18nDiModule
} from "../features/gas-stations/di/modules/GasStationsI18nDiModule"
import DefaultRuGasStationsTextProvider
  from "../features/gas-stations/i18n/core-text-providers/DefaultRuGasStationsTextProvider"
import { DefaultGasStationsDataDiModule } from "../features/gas-stations/di/modules/GasStationsDataDiModule"
import TransportsDiComponent from "../features/transports/di/components/TransportsDiComponent"
import TransportsI18nDiModule, {
  DefaultTransportsI18nDiModule
} from "../features/transports/di/modules/TransportsI18nDiModule"
import DefaultRuTransportsTextProvider
  from "../features/transports/i18n/core-text-providers/DefaultRuTransportsTextProvider"
import { DefaultTransportsPresentationDiModule } from "../features/transports/di/modules/TransportsPresentationDiModule"
import { DefaultTransportsDomainDiModule } from "../features/transports/di/modules/TransportsDomainDiModule"
import { DefaultTransportsDataDiModule } from "../features/transports/di/modules/TransportsDataDiModule"
import CarrierCommercialTermsDiComponent
  from "../features/carrier-commercial-terms/di/components/CarrierCommercialTermsDiComponent"
import {
  DefaultCarrierCommercialTermsPresentationDiModule
} from "../features/carrier-commercial-terms/di/modules/CarrierCommercialTermsPresentationDiModule"
import {
  DefaultCarrierCommercialTermsI18nDiModule
} from "../features/carrier-commercial-terms/di/modules/CarrierCommercialTermsI18nDiModule"
import DefaultRuCarrierCommercialTermsTextProvider
  from "../features/carrier-commercial-terms/i18n/core-text-providers/DefaultRuCarrierCommercialTermsTextProvider"
import {
  DefaultCarrierCommercialTermsDomainDiModule
} from "../features/carrier-commercial-terms/di/modules/CarrierCommercialTermsDomainDiModule"
import {
  DefaultCarrierCommercialTermsDataDiModule
} from "../features/carrier-commercial-terms/di/modules/CarrierCommercialTermsDataDiModule"
import InstallationInstancesDiComponent
  from "../features/installation-instances/di/components/InstallationInstancesDiComponent"
import {
  DefaultInstallationInstancesPresentationDiModule
} from "../features/installation-instances/di/modules/InstallationInstancesPresentationDiModule"
import {
  DefaultInstallationInstancesDomainDiModule
} from "../features/installation-instances/di/modules/InstallationInstancesDomainDiModule"
import {
  DefaultInstallationInstancesDataDiModule
} from "../features/installation-instances/di/modules/InstallationInstancesDataDiModule"
import {
  DefaultInstallationInstancesI18nDiModule
} from "../features/installation-instances/di/modules/InstallationInstancesI18nDiModule"
import DefaultRuInstallationInstancesTextProvider
  from "../features/installation-instances/i18n/core-text-providers/DefaultRuInstallationInstancesTextProvider"
import FuelCompanyCommercialTermsDiComponent
  from "../features/fuel-company-commercial-terms/di/components/FuelCompanyCommercialTermsDiComponent"
import {
  DefaultFuelCompanyCommercialTermsPresentationDiModule
} from "../features/fuel-company-commercial-terms/di/modules/FuelCompanyCommercialTermsPresentationDiModule"
import {
  DefaultFuelCompanyCommercialTermsI18nDiModule
} from "../features/fuel-company-commercial-terms/di/modules/FuelCompanyCommercialTermsI18nDiModule"
import DefaultRuFuelCompanyCommercialTermsTextProvider
  from "../features/fuel-company-commercial-terms/i18n/core-text-providers/DefaultRuFuelCompanyCommercialTermsTextProvider"
import {
  DefaultFuelCompanyCommercialTermsDomainDiModule
} from "../features/fuel-company-commercial-terms/di/modules/FuelCompanyCommercialTermsDomainDiModule"
import {
  DefaultFuelCompanyCommercialTermsDataDiModule
} from "../features/fuel-company-commercial-terms/di/modules/FuelCompanyCommercialTermsDataDiModule"
import FuelPricesDiComponent from "../features/fuel-prices/di/components/FuelPricesDiComponent"
import { DefaultFuelPricesI18nDiModule } from "../features/fuel-prices/di/modules/FuelPricesI18nDiModule"
import DefaultRuFuelPricesTextProvider
  from "../features/fuel-prices/i18n/core-text-providers/DefaultRuFuelPricesTextProvider"
import {
  DefaultFuelPricesPresentationDiModule
} from "../features/fuel-prices/di/modules/FuelPricesPresentationDiModule"
import { DefaultFuelPricesDomainDiModule } from "../features/fuel-prices/di/modules/FuelPricesDomainDiModule"
import { DefaultFuelPricesDataDiModule } from "../features/fuel-prices/di/modules/FuelPricesDataDiModule"
import DriversDiComponent from "../features/drivers/di/components/DriversDiComponent"
import { DefaultDriversI18nDiModule } from "../features/drivers/di/modules/DriversI18nDiModule"
import DefaultRuDriversTextProvider from "../features/drivers/i18n/core-text-providers/DefaultRuDriversTextProvider"
import { DefaultDriversPresentationDiModule } from "../features/drivers/di/modules/DriversPresentationDiModule"
import { DefaultDriversDomainDiModule } from "../features/drivers/di/modules/DriversDomainDiModule"
import { DefaultDriversDataDiModule } from "../features/drivers/di/modules/DriversDataDiModule"
import TripsDiComponent from "../features/trips/di/components/TripsDiComponent"
import { DefaultTripsI18nDiModule } from "../features/trips/di/modules/TripsI18nDiModule"
import DefaultRuTripsTextProvider from "../features/trips/i18n/core-text-providers/DefaultRuTripsTextProvider"
import { DefaultTripsPresentationDiModule } from "../features/trips/di/modules/TripsPresentationDiModule"
import { DefaultTripsDomainDiModule } from "../features/trips/di/modules/TripsDomainDiModule"
import { DefaultTripsDataDiModule } from "../features/trips/di/modules/TripsDataDiModule"
import TripChangingLogDiComponent from "../features/trip-changing-log/di/components/TripChangingLogDiComponent"
import {
  DefaultTripChangingLogPresentationDiModule
} from "../features/trip-changing-log/di/modules/TripChangingLogPresentationDiModule"
import {
  DefaultTripChangingLogI18nDiModule
} from "../features/trip-changing-log/di/modules/TripChangingLogI18nDiModule"
import {
  DefaultTripChangingLogDomainDiModule
} from "../features/trip-changing-log/di/modules/TripChangingLogDomainDiModule"
import {
  DefaultTripChangingLogDataDiModule
} from "../features/trip-changing-log/di/modules/TripChangingLogDataDiModule"
import DefaultRuTripChangingLogTextProvider
  from "../features/trip-changing-log/i18n/core-text-providers/DefaultRuTripChangingLogTextProvider"
import { DefaultCarrierBalancesDataDiModule } from "../features/carrier-balances/di/modules/CarrierBalancesDataDiModule"
import CarrierBalancesDiComponent from "../features/carrier-balances/di/components/CarrierBalancesDiComponent"
import { DefaultCarrierBalancesI18nDiModule } from "../features/carrier-balances/di/modules/CarrierBalancesI18nDiModule"
import DefaultRuCarrierBalancesTextProvider
  from "../features/carrier-balances/i18n/core-text-providers/DefaultRuCarrierBalancesTextProvider"
import {
  DefaultCarrierBalancesPresentationDiModule
} from "../features/carrier-balances/di/modules/CarrierBalancesPresentationDiModule"
import {
  DefaultCarrierBalancesDomainDiModule
} from "../features/carrier-balances/di/modules/CarrierBalancesDomainDiModule"
import FuelCompanyBalancesDiComponent
  from "../features/fuel-company-balances/di/components/FuelCompanyBalancesDiComponent"
import {
  DefaultFuelCompanyBalancesI18nDiModule
} from "../features/fuel-company-balances/di/modules/FuelCompanyBalancesI18nDiModule"
import DefaultRuFuelCompanyBalancesTextProvider
  from "../features/fuel-company-balances/i18n/core-text-providers/DefaultRuFuelCompanyBalancesTextProvider"
import {
  DefaultFuelCompanyBalancesPresentationDiModule
} from "../features/fuel-company-balances/di/modules/FuelCompanyBalancesPresentationDiModule"
import {
  DefaultFuelCompanyBalancesDomainDiModule
} from "../features/fuel-company-balances/di/modules/FuelCompanyBalancesDomainDiModule"
import {
  DefaultFuelCompanyBalancesDataDiModule
} from "../features/fuel-company-balances/di/modules/FuelCompanyBalancesDataDiModule"
import RefuellingOrdersDiComponent from "../features/refuelling-orders/di/components/RefuellingOrdersDiComponent"
import {
  DefaultRefuellingOrdersI18nDiModule
} from "../features/refuelling-orders/di/modules/RefuellingOrdersI18nDiModule"
import DefaultRuRefuellingOrdersTextProvider
  from "../features/refuelling-orders/i18n/core-text-providers/DefaultRuRefuellingOrdersTextProvider"
import {
  DefaultRefuellingOrdersDomainDiModule
} from "../features/refuelling-orders/di/modules/RefuellingOrdersDomainDiModule"
import {
  DefaultRefuellingOrdersDataDiModule
} from "../features/refuelling-orders/di/modules/RefuellingOrdersDataDiModule"
import {
  DefaultRefuellingOrdersPresentationDiModule
} from "../features/refuelling-orders/di/modules/RefuellingOrdersPresentationDiModule"
import ForbiddenDiComponent from "../../admin/features/forbidden/di/components/ForbiddenDiComponent"
import {
  DefaultForbiddenPresentationDiModule
} from "../../admin/features/forbidden/di/modules/ForbiddenPresentationDiModule"
import DefaultForbiddenThemeDiModule from "../features/forbidden/DefaultForbiddenThemeDiModule"
import ForbiddenI18nDiModule, {
  DefaultForbiddenI18nDiModule
} from "../../admin/features/forbidden/di/modules/ForbiddenI18nDiModule"
import DefaultRuForbiddenTextProvider
  from "../../admin/features/forbidden/i18n/forbidden-text-providers/DefaultRuForbiddenTextProvider"
import {
  DefaultAuthenticationDomainDiModule
} from "../../admin/features/authentication/di/modules/AuthenticationDomainDiModule"
import {
  DefaultAuthenticationDataDiModule
} from "../../admin/features/authentication/di/modules/AuthenticationDataDiModule"
import DefaultAuthenticationNetworkDiModule from "../core/di/modules/DefaultAuthenticationNetworkDiModule"
import UsersDiComponent from "../features/users/di/components/UsersDiComponent"
import { DefaultUsersI18nDiModule } from "../features/users/di/modules/UsersI18nDiModule"
import DefaultRuUsersTextProvider from "../features/users/i18n/core-text-providers/DefaultRuUsersTextProvider"
import { DefaultUsersPresentationDiModule } from "../features/users/di/modules/UsersPresentationDiModule"
import { DefaultUsersDomainDiModule } from "../features/users/di/modules/UsersDomainDiModule"
import { DefaultUsersDataDiModule } from "../features/users/di/modules/UsersDataDiModule"
import CarrierUsersDiComponent from "../features/carrier-users/di/components/CarrierUsersDiComponent"
import { DefaultCarrierUsersI18nDiModule } from "../features/carrier-users/di/modules/CarrierUsersI18nDiModule"
import DefaultRuCarrierUsersTextProvider
  from "../features/carrier-users/i18n/core-text-providers/DefaultRuCarrierUsersTextProvider"
import {
  DefaultCarrierUsersPresentationDiModule
} from "../features/carrier-users/di/modules/CarrierUsersPresentationDiModule"
import { DefaultCarrierUsersDomainDiModule } from "../features/carrier-users/di/modules/CarrierUsersDomainDiModule"
import { DefaultCarrierUsersDataDiModule } from "../features/carrier-users/di/modules/CarrierUsersDataDiModule"
import FuelCompanyUsersDiComponent from "../features/fuel-company-users/di/components/FuelCompanyUsersDiComponent"
import {
  DefaultFuelCompanyUsersI18nDiModule
} from "../features/fuel-company-users/di/modules/FuelCompanyUsersI18nDiModule"
import DefaultRuFuelCompanyUsersTextProvider
  from "../features/fuel-company-users/i18n/core-text-providers/DefaultRuFuelCompanyUsersTextProvider"
import {
  DefaultFuelCompanyUsersPresentationDiModule
} from "../features/fuel-company-users/di/modules/FuelCompanyUsersPresentationDiModule"
import {
  DefaultFuelCompanyUsersDomainDiModule
} from "../features/fuel-company-users/di/modules/FuelCompanyUsersDomainDiModule"
import {
  DefaultFuelCompanyUsersDataDiModule
} from "../features/fuel-company-users/di/modules/FuelCompanyUsersDataDiModule"
import CurrentCarrierBalancesDiComponent
  from "../features/curent-carrier-balances/di/components/CurrentCarrierBalancesDiComponent"
import {
  DefaultCurrentCarrierBalancesPresentationDiModule
} from "../features/curent-carrier-balances/di/modules/CurrentCarrierBalancesPresentationDiModule"
import {
  DefaultCurrentCarrierBalancesI18nDiModule
} from "../features/curent-carrier-balances/di/modules/CurrentCarrierBalancesI18nDiModule"
import {
  DefaultCurrentCarrierBalancesDomainDiModule
} from "../features/curent-carrier-balances/di/modules/CurrentCarrierBalancesDomainDiModule"
import {
  DefaultCurrentCarrierBalancesDataDiModule
} from "../features/curent-carrier-balances/di/modules/CurrentCarrierBalancesDataDiModule"
import CurrentFuelCompanyBalancesDiComponent
  from "../features/current-fuel-company-balances/di/components/FuelCompanyBalancesDiComponent"
import {
  DefaultCurrentFuelCompanyBalancesPresentationDiModule
} from "../features/current-fuel-company-balances/di/modules/CurrentFuelCompanyBalancesPresentationDiModule"
import DefaultRuCurrentFuelCompanyBalancesTextProvider
  from "../features/current-fuel-company-balances/i18n/core-text-providers/DefaultRuCurrentFuelCompanyBalancesTextProvider"
import {
  DefaultCurrentFuelCompanyBalancesDomainDiModule
} from "../features/current-fuel-company-balances/di/modules/CurrentFuelCompanyBalancesDomainDiModule"
import {
  DefaultCurrentFuelCompanyBalancesDataDiModule
} from "../features/current-fuel-company-balances/di/modules/CurrentFuelCompanyBalancesDataDiModule"
import {
  DefaultCurrentFuelCompanyBalancesI18nDiModule
} from "../features/current-fuel-company-balances/di/modules/CurrentFuelCompanyBalancesI18nDiModule"
import RefuellingOrdersReportsDiComponent
  from "../features/refuelling-orders-reports/di/components/RefuellingOrdersDiComponent"
import {
  DefaultRefuellingOrdersReportsI18nDiModule
} from "../features/refuelling-orders-reports/di/modules/RefuellingOrdersReportsI18nDiModule"
import DefaultRuRefuellingOrdersReportsTextProvider
  from "../features/refuelling-orders-reports/i18n/core-text-providers/DefaultRuRefuellingOrdersReportsTextProvider"
import {
  DefaultRefuellingOrdersReportsPresentationDiModule
} from "../features/refuelling-orders-reports/di/modules/RefuellingOrdersReportsPresentationDiModule"
import {
  DefaultRefuellingOrdersReportsDomainDiModule
} from "../features/refuelling-orders-reports/di/modules/RefuellingOrdersReportsDomainDiModule"
import {
  DefaultRefuellingOrdersReportsDataDiModule
} from "../features/refuelling-orders-reports/di/modules/RefuellingOrdersReportsDataDiModule"
import CarrierReconciliationReportsDiComponent
  from "../features/carrier-reconciliation-reports/di/components/RefuellingOrdersDiComponent"
import {
  DefaultCarrierReconciliationReportsPresentationDiModule
} from "../features/carrier-reconciliation-reports/di/modules/CarrierReconciliationReportsPresentationDiModule"
import {
  DefaultCarrierReconciliationReportsI18nDiModule
} from "../features/carrier-reconciliation-reports/di/modules/CarrierReconciliationReportsI18nDiModule"
import DefaultRuCarrierReconciliationReportsTextProvider
  from "../features/carrier-reconciliation-reports/i18n/core-text-providers/DefaultRuCarrierReconciliationReportsTextProvider"
import {
  DefaultCarrierReconciliationReportsDomainDiModule
} from "../features/carrier-reconciliation-reports/di/modules/CarrierReconciliationReportsDomainDiModule"
import {
  DefaultCarrierReconciliationReportsDataDiModule
} from "../features/carrier-reconciliation-reports/di/modules/CarrierReconciliationReportsDataDiModule"
import FuelCompanyReconciliationReportsDiComponent
  from "../features/fuel-company-reconciliation-reports/di/components/FuelCompanyReconciliationReportsDiComponent"
import {
  DefaultFuelCompanyReconciliationReportsI18nDiModule
} from "../features/fuel-company-reconciliation-reports/di/modules/FuelCompanyReconciliationReportsI18nDiModule"
import DefaultRuFuelCompanyReconciliationReportsTextProvider
  from "../features/fuel-company-reconciliation-reports/i18n/core-text-providers/DefaultRuFuelCompanyReconciliationReportsTextProvider"
import {
  DefaultFuelCompanyReconciliationReportsPresentationDiModule
} from "../features/fuel-company-reconciliation-reports/di/modules/FuelCompanyReconciliationReportsPresentationDiModule"
import {
  DefaultFuelCompanyReconciliationReportsDomainDiModule
} from "../features/fuel-company-reconciliation-reports/di/modules/FuelCompanyReconciliationReportsDomainDiModule"
import {
  DefaultFuelCompanyReconciliationReportsDataDiModule
} from "../features/fuel-company-reconciliation-reports/di/modules/FuelCompanyReconciliationReportsDataDiModule"
import GasStationBreaksDiComponent from "../features/gas-station-breaks/di/components/FuelPricesDiComponent"
import {
  DefaultGasStationBreaksI18nDiModule
} from "../features/gas-station-breaks/di/modules/GasStationBreaksI18nDiModule"
import DefaultRuGasStationBreaksTextProvider
  from "../features/gas-station-breaks/i18n/core-text-providers/DefaultRuGasStationBreaksTextProvider"
import {
  DefaultGasStationBreakPresentationDiModule
} from "../features/gas-station-breaks/di/modules/GasStationBreaksPresentationDiModule"
import {
  DefaultGasStationBreaksDomainDiModule
} from "../features/gas-station-breaks/di/modules/GasStationBreaksDomainDiModule"
import {
  DefaultGasStationBreaksDataDiModule
} from "../features/gas-station-breaks/di/modules/GasStationBreaksDataDiModule"
import CreditLimitPeriodTypeCoreDiComponent
  from "../features/credit-limit-period-types-core/di/components/CreditLimitPeriodTypeCoreDiComponent"
import {
  DefaultCreditLimitPeriodTypesCoreDomainDiModule
} from "../features/credit-limit-period-types-core/di/modules/CreditLimitPeriodTypesCoreDomainDiModule"
import {
  DefaultCreditLimitPeriodTypesCoreDataDiModule
} from "../features/credit-limit-period-types-core/di/modules/CreditLimitPeriodTypesCoreDataDiModule"
import DeviceTypesCoreDiComponent from "../features/device-types-core/di/components/DeviceTypesCoreDiComponent"
import {
  DefaultDeviceTypesCoreDomainDiModule
} from "../features/device-types-core/di/modules/DeviceTypesCoreDomainDiModule"
import {
  DefaultDeviceTypesCoreDataDiModule
} from "../features/device-types-core/di/modules/DeviceTypesCoreDataDiModule"

const localStorageKeyPrefix = "METANIKA_ADMIN_"
const defaultLanguage = "RU"
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL!
const timeZone = "+03:00"

export default class Di {
  private readonly adminCoreDiComponent: AdminCoreDiComponent
  private readonly adminRootDiComponent: AdminRootDiComponent
  private readonly adminAuthenticationDiComponent: AuthenticationDiComponent
  private readonly adminNotFoundDiComponent: NotFoundDiComponent
  private readonly adminForbiddenDiComponent: ForbiddenDiComponent
  private readonly carriersDiComponent: CarriersDiComponent
  private readonly fuelCompaniesDiComponent: FuelCompaniesDiComponent
  private readonly gasStationsDiComponent: GasStationsDiComponent
  private readonly transportsDiComponent: TransportsDiComponent
  private readonly carrierCommercialTermsDiComponent: CarrierCommercialTermsDiComponent
  private readonly fuelCompanyCommercialTermsDiComponent: FuelCompanyCommercialTermsDiComponent
  private readonly installationInstancesDiComponent: InstallationInstancesDiComponent
  private readonly driversDiComponent: DriversDiComponent
  private readonly fuelPricesDiComponent: FuelPricesDiComponent
  private readonly tripsDiComponent: TripsDiComponent
  private readonly tripChangingLogDiComponent: TripChangingLogDiComponent
  private readonly carrierBalancesDiComponent: CarrierBalancesDiComponent
  private readonly currentCarrierBalancesDiComponent: CurrentCarrierBalancesDiComponent
  private readonly refuellingOrdersDiComponent: RefuellingOrdersDiComponent
  private readonly fuelCompanyBalancesDiComponent: FuelCompanyBalancesDiComponent
  private readonly currentFuelCompanyBalancesDiComponent: CurrentFuelCompanyBalancesDiComponent
  private readonly usersDiComponent: UsersDiComponent
  private readonly carrierUsersDiComponent: CarrierUsersDiComponent
  private readonly fuelCompanyUsersDiComponent: FuelCompanyUsersDiComponent
  private readonly refuellingOrdersReportsDiComponent: RefuellingOrdersReportsDiComponent
  private readonly carrierReconciliationReportsDiComponent: CarrierReconciliationReportsDiComponent
  private readonly fuelCompanyReconciliationReportsDiComponent: FuelCompanyReconciliationReportsDiComponent
  private readonly gasStationBreaksDiComponent: GasStationBreaksDiComponent

  constructor(parameters: {
    readonly adminCoreDiComponent: AdminCoreDiComponent
    readonly adminRootDiComponent: AdminRootDiComponent
    readonly adminAuthenticationDiComponent: AuthenticationDiComponent
    readonly adminNotFoundDiComponent: NotFoundDiComponent
    readonly adminForbiddenDiComponent: ForbiddenDiComponent
    readonly carriersDiComponent: CarriersDiComponent
    readonly fuelCompaniesDiComponent: FuelCompaniesDiComponent
    readonly gasStationsDiComponent: GasStationsDiComponent
    readonly transportsDiComponent: TransportsDiComponent
    readonly carrierCommercialTermsDiComponent: CarrierCommercialTermsDiComponent
    readonly installationInstancesDiComponent: InstallationInstancesDiComponent
    readonly fuelCompanyCommercialTermsDiComponent: FuelCompanyCommercialTermsDiComponent
    readonly driversDiComponent: DriversDiComponent
    readonly fuelPricesDiComponent: FuelPricesDiComponent
    readonly tripsDiComponent: TripsDiComponent
    readonly tripChangingLogDiComponent: TripChangingLogDiComponent
    readonly carrierBalancesDiComponent: CarrierBalancesDiComponent
    readonly currentCarrierBalancesDiComponent: CurrentCarrierBalancesDiComponent
    readonly fuelCompanyBalancesDiComponent: FuelCompanyBalancesDiComponent
    readonly currentFuelCompanyBalancesDiComponent: CurrentFuelCompanyBalancesDiComponent
    readonly refuellingOrdersDiComponent: RefuellingOrdersDiComponent
    readonly usersDiComponent: UsersDiComponent
    readonly carrierUsersDiComponent: CarrierUsersDiComponent
    readonly fuelCompanyUsersDiComponent: FuelCompanyUsersDiComponent
    readonly refuellingOrdersReportsDiComponent: RefuellingOrdersReportsDiComponent
    readonly carrierReconciliationReportsDiComponent: CarrierReconciliationReportsDiComponent
    readonly fuelCompanyReconciliationReportsDiComponent: FuelCompanyReconciliationReportsDiComponent
    readonly gasStationBreaksDiComponent: GasStationBreaksDiComponent
  }) {
    this.adminCoreDiComponent = parameters.adminCoreDiComponent
    this.adminRootDiComponent = parameters.adminRootDiComponent
    this.adminAuthenticationDiComponent = parameters.adminAuthenticationDiComponent
    this.adminNotFoundDiComponent = parameters.adminNotFoundDiComponent
    this.adminForbiddenDiComponent = parameters.adminForbiddenDiComponent
    this.carriersDiComponent = parameters.carriersDiComponent
    this.fuelCompaniesDiComponent = parameters.fuelCompaniesDiComponent
    this.gasStationsDiComponent = parameters.gasStationsDiComponent
    this.transportsDiComponent = parameters.transportsDiComponent
    this.carrierCommercialTermsDiComponent = parameters.carrierCommercialTermsDiComponent
    this.installationInstancesDiComponent = parameters.installationInstancesDiComponent
    this.fuelCompanyCommercialTermsDiComponent = parameters.fuelCompanyCommercialTermsDiComponent
    this.fuelPricesDiComponent = parameters.fuelPricesDiComponent
    this.driversDiComponent = parameters.driversDiComponent
    this.tripsDiComponent = parameters.tripsDiComponent
    this.tripChangingLogDiComponent = parameters.tripChangingLogDiComponent
    this.carrierBalancesDiComponent = parameters.carrierBalancesDiComponent
    this.currentCarrierBalancesDiComponent = parameters.currentCarrierBalancesDiComponent
    this.fuelCompanyBalancesDiComponent = parameters.fuelCompanyBalancesDiComponent
    this.currentFuelCompanyBalancesDiComponent = parameters.currentFuelCompanyBalancesDiComponent
    this.refuellingOrdersDiComponent = parameters.refuellingOrdersDiComponent
    this.usersDiComponent = parameters.usersDiComponent
    this.carrierUsersDiComponent = parameters.carrierUsersDiComponent
    this.fuelCompanyUsersDiComponent = parameters.fuelCompanyUsersDiComponent
    this.refuellingOrdersReportsDiComponent = parameters.refuellingOrdersReportsDiComponent
    this.carrierReconciliationReportsDiComponent = parameters.carrierReconciliationReportsDiComponent
    this.fuelCompanyReconciliationReportsDiComponent = parameters.fuelCompanyReconciliationReportsDiComponent
    this.gasStationBreaksDiComponent = parameters.gasStationBreaksDiComponent
  }

  provideAdminCoreI18n(): CoreI18n {
    return this
      .adminCoreDiComponent
      .coreI18nDiModule
      .provideCoreI18n()
  }

  provideAdminAuthenticationI18n(): AuthenticationI18n {
    return this
      .adminAuthenticationDiComponent
      .authenticationI18nDiModule
      .provideAuthenticationI18n()
  }

  provideRootPage(): ReactElement {
    return this
      .adminRootDiComponent
      .rootPresentationDiModule
      .provideRootPage()
  }

  provideSignInPage(): React.ReactNode {
    return this
      .adminAuthenticationDiComponent
      .authenticationPresentationDiModule
      .provideSignInPage()
  }

  providePasswordRecoveryRequestPage(): React.ReactNode {
    return this
      .adminAuthenticationDiComponent
      .authenticationPresentationDiModule
      .providePasswordRecoveryRequestPage()
  }

  providePasswordRecoveryPage(): React.ReactNode {
    return this
      .adminAuthenticationDiComponent
      .authenticationPresentationDiModule
      .providePasswordRecoveryPage()
  }

  provideCarriersListPage(): React.ReactNode {
    return this
      .carriersDiComponent
      .carriersPresentationDiModule
      .provideCarriersListPage()
  }

  provideCarrierFormPage(): React.ReactNode {
    return this
      .carriersDiComponent
      .carriersPresentationDiModule
      .provideCarrierFormPage()
  }

  provideCarrierInfoPage(): React.ReactNode {
    return this
      .carriersDiComponent
      .carriersPresentationDiModule
      .provideCarrierInfoPage()
  }

  provideFuelCompaniesListPage(): React.ReactNode {
    return this
      .fuelCompaniesDiComponent
      .fuelCompaniesPresentationDiModule
      .provideFuelCompaniesListPage()
  }

  provideFuelCompanyFormPage(): React.ReactNode {
    return this
      .fuelCompaniesDiComponent
      .fuelCompaniesPresentationDiModule
      .provideFuelCompanyFormPage()
  }

  provideFuelCompanyInfoPage(): React.ReactNode {
    return this
      .fuelCompaniesDiComponent
      .fuelCompaniesPresentationDiModule
      .provideFuelCompanyInfoPage()
  }

  provideNotFoundPage(): React.ReactNode {
    return this
      .adminNotFoundDiComponent
      .notFoundPresentationDiModule
      .notFoundPage()
  }

  provideForbiddenPage(): React.ReactNode {
    return this
      .adminForbiddenDiComponent
      .forbiddenPresentationDiModule
      .forbiddenPage()
  }

  provideGasStationsListPage(): React.ReactNode {
    return this
      .gasStationsDiComponent
      .gasStationsPresentationDiModule
      .provideGasStationsListPage()
  }

  provideGasStationFormPage(): React.ReactNode {
    return this
      .gasStationsDiComponent
      .gasStationsPresentationDiModule
      .provideGasStationFormPage()
  }

  provideGasStationInfoPage(): React.ReactNode {
    return this
      .gasStationsDiComponent
      .gasStationsPresentationDiModule
      .provideGasStationInfoPage()
  }

  provideCarrierCommercialTermsListPage(): React.ReactNode {
    return this
      .carrierCommercialTermsDiComponent
      .carrierCommercialTermsPresentationDiModule
      .provideCarrierCommercialTermsListPage()
  }

  provideCarrierCommercialTermFormPage(): React.ReactNode {
    return this
      .carrierCommercialTermsDiComponent
      .carrierCommercialTermsPresentationDiModule
      .provideCarrierCommercialTermsFormPage()
  }

  provideCarrierCommercialTermInfoPage(): React.ReactNode {
    return this
      .carrierCommercialTermsDiComponent
      .carrierCommercialTermsPresentationDiModule
      .provideCarrierCommercialTermsInfoPage()
  }

  provideFuelCompanyCommercialTermsListPage(): React.ReactNode {
    return this
      .fuelCompanyCommercialTermsDiComponent
      .fuelCompanyCommercialTermsPresentationDiModule
      .provideFuelCompanyCommercialTermsListPage()
  }

  provideFuelCompanyCommercialTermInfoPage(): React.ReactNode {
    return this
      .fuelCompanyCommercialTermsDiComponent
      .fuelCompanyCommercialTermsPresentationDiModule
      .provideFuelCompanyCommercialTermsInfoPage()
  }

  provideFuelCompanyCommercialTermFormPage(): React.ReactNode {
    return this
      .fuelCompanyCommercialTermsDiComponent
      .fuelCompanyCommercialTermsPresentationDiModule
      .provideFuelCompanyCommercialTermsFormPage()
  }

  provideInstallationInstancesListPage(): React.ReactNode {
    return this
      .installationInstancesDiComponent
      .installationInstancesPresentationDiModule
      .provideInstallationInstancesListPage()
  }

  provideInstallationInstanceFormPage(): React.ReactNode {
    return this
      .installationInstancesDiComponent
      .installationInstancesPresentationDiModule
      .provideInstallationInstanceFormPage()
  }

  provideInstallationInstanceInfoPage(): React.ReactNode {
    return this
      .installationInstancesDiComponent
      .installationInstancesPresentationDiModule
      .provideInstallationInstanceInfoPage()
  }

  provideTransportsListPage(): React.ReactNode {
    return this
      .transportsDiComponent
      .transportPresentationDiModule
      .provideTransportsListPage()
  }

  provideTransportFormPage(): React.ReactNode {
    return this
      .transportsDiComponent
      .transportPresentationDiModule
      .provideTransportFormPage()
  }

  provideTransportInfoPage(): React.ReactNode {
    return this
      .transportsDiComponent
      .transportPresentationDiModule
      .provideTransportInfoPage()
  }

  provideFuelPricesListPage(): React.ReactNode {
    return this
      .fuelPricesDiComponent
      .fuelPricesPresentationDiModule
      .provideFuelPricesListPage()
  }

  provideFuelPriceFormPage(): React.ReactNode {
    return this
      .fuelPricesDiComponent
      .fuelPricesPresentationDiModule
      .provideFuelPriceFormPage()
  }

  provideFuelPriceInfoPage(): React.ReactNode {
    return this
      .fuelPricesDiComponent
      .fuelPricesPresentationDiModule
      .provideFuelPriceInfoPage()
  }

  provideDriversListPage(): React.ReactNode {
    return this
      .driversDiComponent
      .driversPresentationDiModule
      .provideDriversListPage()
  }

  provideDriverFormPage(): React.ReactNode {
    return this
      .driversDiComponent
      .driversPresentationDiModule
      .provideDriverFormPage()
  }

  provideDriverInfoPage(): React.ReactNode {
    return this
      .driversDiComponent
      .driversPresentationDiModule
      .provideDriverInfoPage()
  }

  provideUsersListPage(): React.ReactNode {
    return this
      .usersDiComponent
      .usersPresentationDiModule
      .provideUsersListPage()
  }

  provideUserFormPage(): React.ReactNode {
    return this
      .usersDiComponent
      .usersPresentationDiModule
      .provideUserFormPage()
  }

  provideUserInfoPage(): React.ReactNode {
    return this
      .usersDiComponent
      .usersPresentationDiModule
      .provideUserInfoPage()
  }

  provideCarrierUsersListPage(): React.ReactNode {
    return this
      .carrierUsersDiComponent
      .carrierUsersPresentationDiModule
      .provideCarrierUsersListPage()
  }

  provideCarrierUserFormPage(): React.ReactNode {
    return this
      .carrierUsersDiComponent
      .carrierUsersPresentationDiModule
      .provideCarrierUserFormPage()
  }

  provideCarrierUserInfoPage(): React.ReactNode {
    return this
      .carrierUsersDiComponent
      .carrierUsersPresentationDiModule
      .provideCarrierUserInfoPage()
  }

  provideFuelCompanyUsersListPage(): React.ReactNode {
    return this
      .fuelCompanyUsersDiComponent
      .fuelCompanyUsersPresentationDiModule
      .provideFuelCompanyUsersListPage()
  }

  provideFuelCompanyUserFormPage(): React.ReactNode {
    return this
      .fuelCompanyUsersDiComponent
      .fuelCompanyUsersPresentationDiModule
      .provideFuelCompanyUserFormPage()
  }

  provideFuelCompanyUserInfoPage(): React.ReactNode {
    return this
      .fuelCompanyUsersDiComponent
      .fuelCompanyUsersPresentationDiModule
      .provideFuelCompanyUserInfoPage()
  }

  provideTripsListPage(): React.ReactNode {
    return this
      .tripsDiComponent
      .tripsPresentationDiModule
      .provideTripsListPage()
  }

  provideTripFormPage(): React.ReactNode {
    return this
      .tripsDiComponent
      .tripsPresentationDiModule
      .provideTripsFormPage()
  }

  provideTripInfoPage(): React.ReactNode {
    return this
      .tripsDiComponent
      .tripsPresentationDiModule
      .provideTripsInfoPage()
  }

  provideTripChangingLogPage(): React.ReactNode {
    return this
      .tripChangingLogDiComponent
      .tripChangingLogPresentationDiModule
      .provideTripChangingLogPage()
  }

  provideCurrentCarrierBalancePage(): React.ReactNode {
    return this
      .currentCarrierBalancesDiComponent
      .carrierBalancesPresentationDiModule
      .provideCurrentCarrierBalancePage()
  }

  provideCarrierBalancePage(): React.ReactNode {
    return this
      .carrierBalancesDiComponent
      .carrierBalancesPresentationDiModule
      .provideCarrierBalancePage()
  }

  provideCarrierBalanceReplenishmentPage(): React.ReactNode {
    return this
      .carrierBalancesDiComponent
      .carrierBalancesPresentationDiModule
      .provideCarrierBalanceChangeDocumentPage()
  }

  provideCurrentFuelCompanyBalancePage(): React.ReactNode {
    return this
      .currentFuelCompanyBalancesDiComponent
      .currentFuelCompanyBalancesPresentationDiModule
      .provideCurrentFuelCompanyBalancePage()
  }

  provideFuelCompanyBalancePage(): React.ReactNode {
    return this
      .fuelCompanyBalancesDiComponent
      .fuelCompanyBalancesPresentationDiModule
      .provideFuelCompanyBalancePage()
  }

  provideFuelCompanyChangeDocumentPage(): React.ReactNode {
    return this
      .fuelCompanyBalancesDiComponent
      .fuelCompanyBalancesPresentationDiModule
      .provideFuelCompanyBalanceChangeDocumentPage()
  }

  provideClaimedRefuellingOrdersListPage(): React.ReactNode {
    return this
      .refuellingOrdersDiComponent
      .refuellingOrdersPresentationDiModule
      .provideClaimedRefuellingOrdersListPage()
  }

  provideCancelledRefuellingOrdersListPage(): React.ReactNode {
    return this
      .refuellingOrdersDiComponent
      .refuellingOrdersPresentationDiModule
      .provideCancelledRefuellingOrdersListPage()
  }

  provideRefuellingOrdersListPage(): React.ReactNode {
    return this
      .refuellingOrdersDiComponent
      .refuellingOrdersPresentationDiModule
      .provideRefuellingOrdersListPage()
  }

  provideRefuellingOrderFormPage(): React.ReactNode {
    return this
      .refuellingOrdersDiComponent
      .refuellingOrdersPresentationDiModule
      .provideRefuellingOrderFormPage()
  }

  provideRefuellingOrdersInfoPage(): React.ReactNode {
    return this
      .refuellingOrdersDiComponent
      .refuellingOrdersPresentationDiModule
      .provideRefuellingOrderInfoPage()
  }

  provideClaimedRefuellingOrdersInfoPage(): React.ReactNode {
    return this
      .refuellingOrdersDiComponent
      .refuellingOrdersPresentationDiModule
      .provideClaimedRefuellingOrderInfoPage()
  }

  provideCancelledRefuellingOrdersInfoPage(): React.ReactNode {
    return this
      .refuellingOrdersDiComponent
      .refuellingOrdersPresentationDiModule
      .provideCancelledRefuellingOrderInfoPage()
  }

  provideRefuellingOrdersReportsListPage(): React.ReactNode {
    return this
      .refuellingOrdersReportsDiComponent
      .refuellingOrdersReportsPresentationDiModule
      .provideRefuellingOrdersReportsListPage()
  }

  provideRefuellingOrdersReportsFormPage(): React.ReactNode {
    return this
      .refuellingOrdersReportsDiComponent
      .refuellingOrdersReportsPresentationDiModule
      .provideRefuellingOrdersReportFormPage()
  }

  provideCarrierReconciliationReportsListPage(): React.ReactNode {
    return this
      .carrierReconciliationReportsDiComponent
      .carrierReconciliationReportsPresentationDiModule
      .provideCarrierReconciliationReportsListPage()
  }

  provideCarrierReconciliationReportsFormPage(): React.ReactNode {
    return this
      .carrierReconciliationReportsDiComponent
      .carrierReconciliationReportsPresentationDiModule
      .provideCarrierReconciliationReportFormPage()
  }

  provideFuelCompanyReconciliationReportsListPage(): React.ReactNode {
    return this
      .fuelCompanyReconciliationReportsDiComponent
      .fuelCompanyReconciliationReportsPresentationDiModule
      .provideFuelCompanyReconciliationReportsListPage()
  }

  provideFuelCompanyReconciliationReportsFormPage(): React.ReactNode {
    return this
      .fuelCompanyReconciliationReportsDiComponent
      .fuelCompanyReconciliationReportsPresentationDiModule
      .provideFuelCompanyReconciliationReportFormPage()
  }

  provideGasStationBreaksListPage(): React.ReactNode {
    return this
      .gasStationBreaksDiComponent
      .gasStationBreaksPresentationDiModule
      .provideGasStationBreaksListPage()
  }

  provideGasStationBreakFormPage(): React.ReactNode {
    return this
      .gasStationBreaksDiComponent
      .gasStationBreaksPresentationDiModule
      .provideGasStationBreakFormPage()
  }

  provideGasStationBreakInfoPage(): React.ReactNode {
    return this
      .gasStationBreaksDiComponent
      .gasStationBreaksPresentationDiModule
      .provideGasStationBreakInfoPage()
  }
}

export function createDi(): Di {
  const adminCoreLocalDiModule: AdminCoreLocalDiModule = new DefaultAdminCoreLocalDiModule({
    localStorageKeyPrefix
  })

  const appNetworkDiModule: AppNetworkDiModule = new DefaultAppNetworkDiModule({
    backendBaseUrl,
    coreLocalDiModule: adminCoreLocalDiModule
  })

  const adminCoreDomainDiModule: AdminCoreDomainDiModule = new DefaultAdminCoreDomainDiModule({
    coreDataDiModule: new DefaultAdminCoreDataDiModule({
      coreLocalDiModule: adminCoreLocalDiModule,
      coreNetworkSourceDiModule: new DefaultCoreNetworkDiModule({
        appNetworkDiModule: appNetworkDiModule
      })
    })
  })

  const objectsDomainDiModule = new AdminObjectsDomainDiModule({
    objectsDataDiModule: new DefaultObjectsDataDiModule()
  })

  const objectsPresentationDiModule: ObjectsPresentationDiModule =
    new DefaultObjectsPresentationDiModule({
      objectsDomainDiModule,
      coreDomainDiModule: adminCoreDomainDiModule
    })

  const coreI18nDiModule = new DefaultAdminCoreI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuAdminCoreTextProvider()
    }
  })

  const appI18nDiModule: AppI18nDiModule = new DefaultAppI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuAppTextProvider()
    }
  })

  const notFoundI18nDiModule: NotFoundI18nDiModule = new DefaultNotFoundI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuNotFoundTextProvider()
    }
  })

  const forbiddenI18nDiModule: ForbiddenI18nDiModule = new DefaultForbiddenI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuForbiddenTextProvider()
    }
  })

  const carriersI18nDiModule: CarriersI18nDiModule = new DefaultCarriersI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuCarriersTextProvider()
    }
  })

  const fuelCompaniesI18nDiModule: FuelCompaniesI18nDiModule = new DefaultFuelCompaniesI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuFuelCompaniesTextProvider()
    }
  })

  const gasStationsI18nDiModule: GasStationsI18nDiModule = new DefaultGasStationsI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuGasStationsTextProvider()
    }
  })

  const carrierCommercialTermsI18nDiModule = new DefaultCarrierCommercialTermsI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuCarrierCommercialTermsTextProvider()
    }
  })

  const installationInstancesI18nDiModule = new DefaultInstallationInstancesI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuInstallationInstancesTextProvider()
    }
  })

  const fuelCompanyCommercialTermsI18nDiModule = new DefaultFuelCompanyCommercialTermsI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuFuelCompanyCommercialTermsTextProvider()
    }
  })

  const driversI18nDiModule = new DefaultDriversI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuDriversTextProvider()
    }
  })

  const usersI18nDiModule = new DefaultUsersI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuUsersTextProvider()
    }
  })

  const carrierUsersI18nDiModule = new DefaultCarrierUsersI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuCarrierUsersTextProvider()
    }
  })

  const fuelCompanyUsersI18nDiModule = new DefaultFuelCompanyUsersI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuFuelCompanyUsersTextProvider()
    }
  })

  const transportsI18nDiModule: TransportsI18nDiModule = new DefaultTransportsI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuTransportsTextProvider()
    }
  })

  const fuelPricesI18nDiModule = new DefaultFuelPricesI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuFuelPricesTextProvider()
    }
  })

  const tripsI18nDiModule = new DefaultTripsI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuTripsTextProvider()
    }
  })

  const tripChangingLogI18nDiModule = new DefaultTripChangingLogI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuTripChangingLogTextProvider()
    }
  })

  const carrierBalancesI18nDiModule = new DefaultCarrierBalancesI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuCarrierBalancesTextProvider()
    }
  })

  const currentCarrierBalancesI18nDiModule = new DefaultCurrentCarrierBalancesI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuCarrierBalancesTextProvider()
    }
  })

  const refuellingOrdersI18nDiModule = new DefaultRefuellingOrdersI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuRefuellingOrdersTextProvider()
    }
  })

  const fuelCompanyBalancesI18nDiModule = new DefaultFuelCompanyBalancesI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuFuelCompanyBalancesTextProvider()
    }
  })

  const currentFuelCompanyBalancesI18nDiModule = new DefaultCurrentFuelCompanyBalancesI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuCurrentFuelCompanyBalancesTextProvider()
    }
  })

  const appDataDiModule = new DefaultAppDataDiModule({
    appNetworkDiModule,
    appI18nDiModule
  })

  const refuellingOrdersReportsI18nDiModule = new DefaultRefuellingOrdersReportsI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuRefuellingOrdersReportsTextProvider()
    }
  })

  const carrierReconciliationReportsI18nDiModule = new DefaultCarrierReconciliationReportsI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuCarrierReconciliationReportsTextProvider()
    }
  })

  const fuelCompanyReconciliationReportsI18nDiModule = new DefaultFuelCompanyReconciliationReportsI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuFuelCompanyReconciliationReportsTextProvider()
    }
  })

  const gasStationBreaksI18nDiModule = new DefaultGasStationBreaksI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuGasStationBreaksTextProvider()
    }
  })

  const creditLimitPeriodTypeCoreDiComponent: CreditLimitPeriodTypeCoreDiComponent = {
    creditLimitPeriodTypesCoreDomainDiModule: new DefaultCreditLimitPeriodTypesCoreDomainDiModule({
      creditLimitPeriodTypesCoreDataDiModule: new DefaultCreditLimitPeriodTypesCoreDataDiModule({
        appDataDiModule
      })
    })
  }

  const deviceTypesCoreDiComponent: DeviceTypesCoreDiComponent = {
    deviceTypesCoreDomainDiModule: new DefaultDeviceTypesCoreDomainDiModule({
      deviceTypesCoreDataDiModule: new DefaultDeviceTypesCoreDataDiModule({
        appDataDiModule
      })
    })
  }

  return new Di({
    adminCoreDiComponent: {
      coreI18nDiModule
    },
    adminRootDiComponent: {
      rootPresentationDiModule: new DefaultRootPresentationDiModule({
        coreDomainDiModule: adminCoreDomainDiModule,
        rootDomainDiModule: new DefaultRootDomainDiModule({
          rootDataDiModule: new DefaultRootDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    adminAuthenticationDiComponent: {
      authenticationI18nDiModule: new DefaultAuthenticationI18nDiModule({
        defaultLanguage,
        textProviderByLanguage: {
          "RU": new DefaultRuAuthenticationTextProvider()
        }
      }),
      authenticationPresentationDiModule: new DefaultAuthenticationPresentationDiModule({
        coreDomainDiModule: adminCoreDomainDiModule,
        authenticationDomainDiModule: new DefaultAuthenticationDomainDiModule({
          authenticationDataDiModule: new DefaultAuthenticationDataDiModule({
            authenticationNetworkDiModule: new DefaultAuthenticationNetworkDiModule({
              appNetworkDiModule: appNetworkDiModule
            })
          })
        })
      })
    },
    adminNotFoundDiComponent: {
      notFoundPresentationDiModule: new DefaultNotFoundPresentationDiModule({
        notFoundThemeDiModule: new DefaultNotFoundThemeDiModule(),
        notFoundI18nDiModule: notFoundI18nDiModule
      })
    },
    adminForbiddenDiComponent: {
      forbiddenPresentationDiModule: new DefaultForbiddenPresentationDiModule({
        forbiddenThemeDiModule: new DefaultForbiddenThemeDiModule(),
        forbiddenI18nDiModule: forbiddenI18nDiModule
      })
    },
    carriersDiComponent: {
      carriersPresentationDiModule: new DefaultCarriersPresentationDiModule({
        objectsPresentationDiModule,
        carriersI18nDiModule,
        carriersDomainDiModule: new DefaultCarriersDomainDiModule({
          carriersDataDiModule: new DefaultCarriersDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    fuelCompaniesDiComponent: {
      fuelCompaniesPresentationDiModule: new DefaultFuelCompaniesPresentationDiModule({
        objectsPresentationDiModule,
        appI18nDiModule,
        fuelCompaniesI18nDiModule,
        fuelCompaniesDomainDiModule: new DefaultFuelCompaniesDomainDiModule({
          fuelCompaniesDataDiModule: new DefaultFuelCompaniesDataDiModule({
            appDataDiModule
          })
        }),
        creditLimitPeriodTypesCoreDomainDiModule:
          creditLimitPeriodTypeCoreDiComponent.creditLimitPeriodTypesCoreDomainDiModule
      })
    },
    gasStationsDiComponent: {
      gasStationsPresentationDiModule: new DefaultGasStationsPresentationDiModule({
        objectsPresentationDiModule,
        coreI18nDiModule,
        gasStationsI18nDiModule,
        gasStationsDomainDiModule: new DefaultGasStationsDomainDiModule({
          gasStationsDataDiModule: new DefaultGasStationsDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    carrierCommercialTermsDiComponent: {
      carrierCommercialTermsPresentationDiModule: new DefaultCarrierCommercialTermsPresentationDiModule({
        timeZone,
        objectsPresentationDiModule,
        coreI18nDiModule,
        appI18nDiModule,
        carrierCommercialTermsI18nDiModule,
        coreDomainDiModule: adminCoreDomainDiModule,
        carrierCommercialTermsDomainDiModule: new DefaultCarrierCommercialTermsDomainDiModule({
          carrierCommercialTermsDataDiModule: new DefaultCarrierCommercialTermsDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    fuelCompanyCommercialTermsDiComponent: {
      fuelCompanyCommercialTermsPresentationDiModule: new DefaultFuelCompanyCommercialTermsPresentationDiModule({
        timeZone,
        objectsPresentationDiModule,
        coreI18nDiModule,
        appI18nDiModule,
        coreDomainDiModule: adminCoreDomainDiModule,
        fuelCompanyCommercialTermsI18nDiModule,
        fuelCompanyCommercialTermsDomainDiModule: new DefaultFuelCompanyCommercialTermsDomainDiModule({
          fuelCompanyCommercialTermsDataDiModule: new DefaultFuelCompanyCommercialTermsDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    installationInstancesDiComponent: {
      installationInstancesPresentationDiModule: new DefaultInstallationInstancesPresentationDiModule({
        objectsPresentationDiModule,
        coreI18nDiModule,
        installationInstancesI18nDiModule,
        deviceTypesDomainCoreDiModule: deviceTypesCoreDiComponent.deviceTypesCoreDomainDiModule,
        installationInstancesDomainDiModule: new DefaultInstallationInstancesDomainDiModule({
          installationInstancesDataDiModule: new DefaultInstallationInstancesDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    transportsDiComponent: {
      transportPresentationDiModule: new DefaultTransportsPresentationDiModule({
        objectsPresentationDiModule,
        coreI18nDiModule,
        transportsI18nDiModule,
        transportsDomainDiModule: new DefaultTransportsDomainDiModule({
          transportsDataDiModule: new DefaultTransportsDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    fuelPricesDiComponent: {
      fuelPricesPresentationDiModule: new DefaultFuelPricesPresentationDiModule({
        timeZone,
        coreI18nDiModule,
        objectsPresentationDiModule,
        fuelPricesI18nDiModule,
        fuelPricesDomainDiModule: new DefaultFuelPricesDomainDiModule({
          fuelPricesDataDiModule: new DefaultFuelPricesDataDiModule({
            appDataDiModule,
            timeZone
          })
        })
      })
    },
    driversDiComponent: {
      driversPresentationDiModule: new DefaultDriversPresentationDiModule({
        timeZone,
        coreI18nDiModule,
        objectsPresentationDiModule,
        appI18nDiModule,
        driversI18nDiModule,
        driversDomainDiModule: new DefaultDriversDomainDiModule({
          driversDataDiModule: new DefaultDriversDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    usersDiComponent: {
      usersPresentationDiModule: new DefaultUsersPresentationDiModule({
        objectsPresentationDiModule,
        appI18nDiModule,
        usersI18nDiModule,
        usersDomainDiModule: new DefaultUsersDomainDiModule({
          usersDataDiModule: new DefaultUsersDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    carrierUsersDiComponent: {
      carrierUsersPresentationDiModule: new DefaultCarrierUsersPresentationDiModule({
        coreDomainDiModule: adminCoreDomainDiModule,
        objectsPresentationDiModule,
        appI18nDiModule,
        carrierUsersI18nDiModule,
        carrierUsersDomainDiModule: new DefaultCarrierUsersDomainDiModule({
          carrierUsersDataDiModule: new DefaultCarrierUsersDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    fuelCompanyUsersDiComponent: {
      fuelCompanyUsersPresentationDiModule: new DefaultFuelCompanyUsersPresentationDiModule({
        objectsPresentationDiModule,
        appI18nDiModule,
        fuelCompanyUsersI18nDiModule,
        coreDomainDiModule: adminCoreDomainDiModule,
        fuelCompanyUsersDomainDiModule: new DefaultFuelCompanyUsersDomainDiModule({
          fuelCompanyUsersDataDiModule: new DefaultFuelCompanyUsersDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    tripsDiComponent: {
      tripsPresentationDiModule: new DefaultTripsPresentationDiModule({
        timeZone,
        coreI18nDiModule,
        objectsPresentationDiModule,
        appI18nDiModule,
        tripsI18nDiModule,
        tripsDomainDiModule: new DefaultTripsDomainDiModule({
          tripsDataDiModule: new DefaultTripsDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    tripChangingLogDiComponent: {
      tripChangingLogPresentationDiModule: new DefaultTripChangingLogPresentationDiModule({
        timeZone,
        coreI18nDiModule,
        objectsPresentationDiModule,
        appI18nDiModule,
        tripChangingLogI18nDiModule,
        tripChangingLogDomainDiModule: new DefaultTripChangingLogDomainDiModule({
          tripChangingLogDataDiModule: new DefaultTripChangingLogDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    carrierBalancesDiComponent: {
      carrierBalancesPresentationDiModule: new DefaultCarrierBalancesPresentationDiModule({
        timeZone,
        objectsDomainDiModule,
        carrierBalancesI18nDiModule,
        coreDomainDiModule: adminCoreDomainDiModule,
        carrierBalancesDomainDiModule: new DefaultCarrierBalancesDomainDiModule({
          carrierBalancesDiModule: new DefaultCarrierBalancesDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    currentCarrierBalancesDiComponent: {
      carrierBalancesPresentationDiModule: new DefaultCurrentCarrierBalancesPresentationDiModule({
        timeZone,
        objectsDomainDiModule,
        carrierBalancesI18nDiModule: currentCarrierBalancesI18nDiModule,
        coreDomainDiModule: adminCoreDomainDiModule,
        carrierBalancesDomainDiModule: new DefaultCurrentCarrierBalancesDomainDiModule({
          carrierBalancesDiModule: new DefaultCurrentCarrierBalancesDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    refuellingOrdersDiComponent: {
      refuellingOrdersPresentationDiModule: new DefaultRefuellingOrdersPresentationDiModule({
        coreDomainDiModule: adminCoreDomainDiModule,
        timeZone,
        objectsPresentationDiModule,
        coreI18nDiModule,
        appI18nDiModule,
        refuellingOrdersI18nDiModule,
        refuellingOrdersDomainDiModule: new DefaultRefuellingOrdersDomainDiModule({
          refuellingOrdersDataDiModule: new DefaultRefuellingOrdersDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    fuelCompanyBalancesDiComponent: {
      fuelCompanyBalancesPresentationDiModule: new DefaultFuelCompanyBalancesPresentationDiModule({
        timeZone,
        objectsDomainDiModule,
        coreDomainDiModule: adminCoreDomainDiModule,
        fuelCompanyBalancesI18nDiModule,
        appI18nDiModule,
        fuelCompanyBalancesDomainDiModule: new DefaultFuelCompanyBalancesDomainDiModule({
          fuelCompanyBalancesDataDiModule: new DefaultFuelCompanyBalancesDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    currentFuelCompanyBalancesDiComponent: {
      currentFuelCompanyBalancesPresentationDiModule: new DefaultCurrentFuelCompanyBalancesPresentationDiModule({
        timeZone,
        objectsDomainDiModule,
        appI18nDiModule,
        coreDomainDiModule: adminCoreDomainDiModule,
        fuelCompanyBalancesI18nDiModule: currentFuelCompanyBalancesI18nDiModule,
        fuelCompanyBalancesDomainDiModule: new DefaultCurrentFuelCompanyBalancesDomainDiModule({
          fuelCompanyBalancesDataDiModule: new DefaultCurrentFuelCompanyBalancesDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    refuellingOrdersReportsDiComponent: {
      refuellingOrdersReportsPresentationDiModule: new DefaultRefuellingOrdersReportsPresentationDiModule({
        timeZone,
        appI18nDiModule,
        objectsPresentationDiModule,
        refuellingOrdersReportsI18nDiModule: refuellingOrdersReportsI18nDiModule,
        refuellingOrdersReportsDomainDiModule: new DefaultRefuellingOrdersReportsDomainDiModule({
          refuellingOrdersReportsDataDiModule: new DefaultRefuellingOrdersReportsDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    carrierReconciliationReportsDiComponent: {
      carrierReconciliationReportsPresentationDiModule: new DefaultCarrierReconciliationReportsPresentationDiModule({
        timeZone,
        objectsPresentationDiModule,
        coreDomainDiModule: adminCoreDomainDiModule,
        carrierReconciliationReportsI18nDiModule: carrierReconciliationReportsI18nDiModule,
        carrierReconciliationReportsDomainDiModule: new DefaultCarrierReconciliationReportsDomainDiModule({
          carrierReconciliationReportsDataDiModule: new DefaultCarrierReconciliationReportsDataDiModule({
            appDataDiModule
          })
        })
      })
    },
    fuelCompanyReconciliationReportsDiComponent: {
      fuelCompanyReconciliationReportsPresentationDiModule:
        new DefaultFuelCompanyReconciliationReportsPresentationDiModule({
          timeZone,
          objectsPresentationDiModule,
          coreDomainDiModule: adminCoreDomainDiModule,
          fuelCompanyReconciliationReportsI18nDiModule: fuelCompanyReconciliationReportsI18nDiModule,
          fuelCompanyReconciliationReportsDomainDiModule: new DefaultFuelCompanyReconciliationReportsDomainDiModule({
            fuelCompanyReconciliationReportsDataDiModule: new DefaultFuelCompanyReconciliationReportsDataDiModule({
              appDataDiModule
            })
          })
        })
    },
    gasStationBreaksDiComponent: {
      gasStationBreaksPresentationDiModule:
        new DefaultGasStationBreakPresentationDiModule({
          timeZone,
          objectsPresentationDiModule,
          coreI18nDiModule,
          gasStationBreaksI18nDiModule,
          gasStationBreaksDomainDiModule: new DefaultGasStationBreaksDomainDiModule({
            gasStationBreaksDataDiModule: new DefaultGasStationBreaksDataDiModule({
              appDataDiModule,
              timeZone
            })
          })
        })
    }
  })
}
