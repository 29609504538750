import DriversTextProvider from "../DriversTextProvider"

export default class DefaultRuDriversTextProvider implements DriversTextProvider {
  phoneNumberField(): string {
    return "Номер телефона"
  }

  listTitle(): string {
    return "Водители"
  }

  firstNameField(): string {
    return "Имя"
  }

  middleNameField(): string {
    return "Отчество"
  }

  lastNameField(): string {
    return "Фамилия"
  }

  carrierField(): string {
    return "Перевозчик"
  }

  newObjectTitle(): string {
    return "Новый водитель"
  }

  birthdateField(): string {
    return "Дата рождения"
  }

  refuellingOrdersLink(): string {
    return "Заправки"
  }

  driverLicenseNumberField(): string {
    return "Номер водительского удостоверения"
  }

  statusField(): string {
    return "Статус"
  }

  blockedField(): string {
    return "Блокировка"
  }

  statusValueActive(): string {
    return "Активен"
  }

  statusValueBlocked(): string {
    return "Заблокирован"
  }

  enableLimitControlViaTripsField(): string {
    return "Включить управление лимитами через детализацию рейсов"
  }

  dailyMoneyLimitField(): string {
    return "Лимит на сутки, руб."
  }
}
