import NetworkFuelPrice from "../entities/fuel-prices/NetworkFuelPrice"
import FuelPrice from "../../domain/fuel-prices/FuelPrice"
import FuelsMapper from "./FuelsMapper"
import GasStationsMapper from "./GasStationsMapper"
import FuelCompaniesMapper from "./FuelCompaniesMapper"

export default class FuelPricesMapper {
  mapNetworkToDomain({
    fuelPrice
  }: {
    readonly fuelPrice: NetworkFuelPrice
  }): FuelPrice {
    const { fuel, gasStations, fuelCompany } = fuelPrice
    const gasStationsMapper = new GasStationsMapper()
    const fuelCompaniesMapper = new FuelCompaniesMapper()
    const fuelsMapper = new FuelsMapper()

    return {
      id: fuelPrice.id,
      value: fuelPrice.value,
      formattedValue: fuelPrice.formattedValue,
      startDate: fuelPrice.startDate,
      fuelId: fuelPrice.fuelId,
      fuel: fuel && fuelsMapper.mapNetworkToDomain({ fuel }),
      fuelCompanyId: fuelPrice.fuelCompanyId,
      fuelCompany: fuelCompany && fuelCompaniesMapper.mapNetworkToDomain({
        fuelCompany,
        creditLimitPeriodTypeSelectOptions: []
      }),
      gasStationIds: fuelPrice.gasStationIds,
      gasStations: gasStations && gasStations.map((gasStation) => {
        return gasStationsMapper.mapNetworkToDomain({ gasStation })
      })
    }
  }

  mapDomainToNetwork({
    fuelPrice,
    timeZone
  }: {
    readonly fuelPrice: FuelPrice
    readonly timeZone: string
  }): NetworkFuelPrice {
    return new NetworkFuelPrice({
      value: fuelPrice.value,
      startDate: fuelPrice.startDate,
      fuelId: fuelPrice.fuelId,
      gasStationIds: fuelPrice.gasStationIds,
      timeZone: timeZone
    })
  }
}
