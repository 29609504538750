import NetworkUser from "../entities/users/NetworkUser"
import User from "../../domain/users/User"
import CarriersMapper from "./CarriersMapper"
import UserProfilesMapper from "./UserProfilesMapper"
import PhoneNumberMapper from "./PhoneNumberMapper"
import CarrierAppAdministratorsMapper from "./CarrierAppAdministratorsMapper"
import FuelCompanyAppAdministratorsMapper from "./FuelCompanyAppAdministratorsMapper"

export default class UsersMapper {
  mapNetworkToDomain({
    user
  }: {
    readonly user: NetworkUser
  }): User {
    const { carrier, profile, phoneNumber, carrierAppAdministrator, fuelCompanyAppAdministrator } = user
    return {
      id: user.id,
      blocked: user.blocked,
      emailAddress: user.emailAddress,
      carrierId: user.carrierId,
      carrier: carrier && new CarriersMapper().mapNetworkToDomain({ carrier }),
      profile: profile && new UserProfilesMapper().mapNetworkToDomain({ profile }),
      phoneNumber: phoneNumber && new PhoneNumberMapper().mapNetworkToDomain({ phoneNumber }),
      carrierAppAdministrator:
        carrierAppAdministrator && new CarrierAppAdministratorsMapper().mapNetworkToDomain({ carrierAppAdministrator }),
      fuelCompanyAppAdministrator: fuelCompanyAppAdministrator &&
        new FuelCompanyAppAdministratorsMapper().mapNetworkToDomain({ fuelCompanyAppAdministrator })
    }
  }

  mapDomainToNetwork({
    user
  }: {
    readonly user: User
  }): NetworkUser {
    const { profile, phoneNumber, carrierAppAdministrator, fuelCompanyAppAdministrator } = user
    return {
      blocked: user.blocked,
      emailAddress: user.emailAddress,
      carrierId: user.carrierId,
      profile: profile && new UserProfilesMapper().mapDomainToNetwork({ profile }),
      phoneNumber: phoneNumber && new PhoneNumberMapper().mapDomainToNetwork({ phoneNumber }),
      dailyMoneyLimit: user.dailyMoneyLimit,
      enableLimitControlViaTrips: user.enableLimitControlViaTrips,
      carrierAppAdministrator:
        carrierAppAdministrator && new CarrierAppAdministratorsMapper().mapDomainToNetwork({ carrierAppAdministrator }),
      fuelCompanyAppAdministrator: fuelCompanyAppAdministrator &&
        new FuelCompanyAppAdministratorsMapper().mapDomainToNetwork({ fuelCompanyAppAdministrator })
    }
  }
}
