import GetDeviceTypesUseCase from "../../domain/use-cases/GetDeviceTypesUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DeviceTypesCoreDataDiModule from "./DeviceTypesCoreDataDiModule"

export default interface DeviceTypesCoreDomainDiModule {
  provideGetDeviceTypesUseCase(): GetDeviceTypesUseCase
}

export class DefaultDeviceTypesCoreDomainDiModule extends DiModule implements DeviceTypesCoreDomainDiModule {
  private readonly deviceTypesCoreDataDiModule: DeviceTypesCoreDataDiModule

  constructor(parameters: {
    readonly deviceTypesCoreDataDiModule: DeviceTypesCoreDataDiModule
  }) {
    super()
    this.deviceTypesCoreDataDiModule = parameters.deviceTypesCoreDataDiModule
  }

  provideGetDeviceTypesUseCase(): GetDeviceTypesUseCase {
    return this.single(
      GetDeviceTypesUseCase.name,
      () => new GetDeviceTypesUseCase({
        deviceTypesRepository: this.deviceTypesCoreDataDiModule.provideDeviceTypesRepository()
      })
    )
  }
}
