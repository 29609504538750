import { Expose, Transform, Type } from "class-transformer"
import NetworkCarrier from "../carriers/NetworkCarrier"
import NetworkUserProfile from "../user-profiles/NetworkUserProfile"
import NetworkPhoneNumber from "../phone-number/NetworkPhoneNumber"
import NetworkCarrierAppAdministrator from "../carrier-app-administrator/response-bodies/NetworkCarrierAppAdministrator"
import NetworkFuelCompanyAppAdministrator
  from "../fuel-company-app-administrator/response-bodies/NetworkFuelCompanyAppAdministrator"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"

export default class NetworkUser {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Boolean)
  readonly blocked?: boolean | null

  @Expose()
  @Type(() => Boolean)
  readonly isOwner?: boolean | null

  @Expose()
  @Type(() => String)
  readonly emailAddress?: string | null

  @Expose()
  @Type(() => Number)
  readonly carrierId?: number | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carrier?: NetworkCarrier | null

  @Expose()
  @Type(() => NetworkUserProfile)
  readonly profile?: NetworkUserProfile | null

  @Expose()
  @Type(() => NetworkPhoneNumber)
  readonly phoneNumber?: NetworkPhoneNumber | null

  @Expose()
  @Type(() => NetworkCarrierAppAdministrator)
  readonly carrierAppAdministrator?: NetworkCarrierAppAdministrator | null

  @Expose()
  @Type(() => NetworkFuelCompanyAppAdministrator)
  readonly fuelCompanyAppAdministrator?: NetworkFuelCompanyAppAdministrator | null

  @Expose()
  @Type(() => Boolean)
  readonly enableLimitControlViaTrips?: boolean | null

  @Expose()
  @Transform(transformDecimal)
  readonly dailyMoneyLimit?: Decimal | null
}
