import InstallationInstancesRepository from "../../domain/repositories/InstallationInstancesRepository"
import {
  GetInstallationInstancesParameters
} from "../../domain/use-cases/installation-instances/GetInstallationInstancesUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import {
  CreateInstallationInstanceParameters
} from "../../domain/use-cases/installation-instances/CreateInstallationInstanceUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import {
  GetInstallationInstanceParameters
} from "../../domain/use-cases/installation-instances/GetInstallationInstanceUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import {
  UpdateInstallationInstanceParameters
} from "../../domain/use-cases/installation-instances/UpdateInstallationInstanceUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import InstallationInstancesNetworkSource
  from "../../../../core/data/sources/network/InstallationInstancesNetworkSource"
import InstallationInstance from "../../../../core/domain/installation-instance/InstallationInstance"
import NetworkInstallationInstancesRequestFilter
  from "../../../../core/data/entities/installation-instance/request-queries/NetworkInstallationInstancesRequestFilter"
import InstallationInstancesMapper from "../../../../core/data/mappers/InstallationInstancesMapper"
import InstallationInstanceError from "../../../../core/domain/installation-instance/InstallationInstanceError"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultInstallationInstancesRepository implements InstallationInstancesRepository {
  private readonly installationInstancesNetworkSource: InstallationInstancesNetworkSource

  constructor(parameters: {
    readonly installationInstancesNetworkSource: InstallationInstancesNetworkSource
  }) {
    this.installationInstancesNetworkSource = parameters.installationInstancesNetworkSource
  }

  async getInstallationInstances({
    filter,
    query,
    pagination,
    sort
  }: GetInstallationInstancesParameters): Promise<GetObjectsResult<InstallationInstance>> {
    const fuelCompanyId = filter?.fuelCompanyId
    const gasStationId = filter?.gasStationId
    const result = await this.installationInstancesNetworkSource.getInstallationInstances({
      filter: new NetworkInstallationInstancesRequestFilter({
        query,
        fuelCompanyIds: isPresent(fuelCompanyId) ? [fuelCompanyId] : null,
        gasStationIds: isPresent(gasStationId) ? [gasStationId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success": {
        const installationInstancesMapper = new InstallationInstancesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.installationInstances!.map((installationInstance) => {
              return installationInstancesMapper.mapNetworkToDomain({
                installationInstance
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }

  async getInstallationInstance({
    objectId
  }: GetInstallationInstanceParameters): Promise<GetObjectResult<InstallationInstance>> {
    const result = await this.installationInstancesNetworkSource.getInstallationInstance({
      installationInstanceId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new InstallationInstancesMapper().mapNetworkToDomain({
            installationInstance: result.data.installationInstance!
          })
        }
      default:
        return result
    }
  }

  async createInstallationInstance(
    { object: installationInstance }: CreateInstallationInstanceParameters
  ): Promise<CreateObjectResult<InstallationInstance, InstallationInstanceError>> {
    const mapper = new InstallationInstancesMapper()
    const result = await this.installationInstancesNetworkSource.createInstallationInstance({
      installationInstance: mapper.mapDomainToNetwork({ installationInstance })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            installationInstance: result.data.installationInstance!
          })
        }
      default:
        return result
    }
  }

  async updateInstallationInstance(
    { objectId: installationInstanceId, object: installationInstance }: UpdateInstallationInstanceParameters
  ): Promise<UpdateObjectResult<InstallationInstance, InstallationInstanceError>> {
    const mapper = new InstallationInstancesMapper()
    const result = await this.installationInstancesNetworkSource.updateInstallationInstance({
      installationInstanceId: parseInt(installationInstanceId),
      installationInstance: mapper.mapDomainToNetwork({
        installationInstance
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            installationInstance: result.data.installationInstance!
          })
        }
      default:
        return result
    }
  }
}
