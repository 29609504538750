import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import InstallationInstancesDomainDiModule from "./InstallationInstancesDomainDiModule"
import InstallationInstancesTableProvider from "../../presentation/table-providers/InstallationInstancesTableProvider"
import InstallationInstanceFormProvider from "../../presentation/form-providers/InstallationInstanceFormProvider"
import InstallationInstanceInfoProvider from "../../presentation/info-providers/InstallationInstanceInfoProvider"
import InstallationInstancesI18nDiModule from "./InstallationInstancesI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"
import DeviceTypesCoreDomainDiModule from "../../../device-types-core/di/modules/DeviceTypesCoreDomainDiModule"

const moduleKey = "installationInstances"

export default interface InstallationInstancesPresentationDiModule {
  provideInstallationInstancesListPage(): React.ReactNode

  provideInstallationInstanceInfoPage(): React.ReactNode

  provideInstallationInstanceFormPage(): React.ReactNode
}

export class DefaultInstallationInstancesPresentationDiModule
  extends DiModule
  implements InstallationInstancesPresentationDiModule {

  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly installationInstancesI18nDiModule: InstallationInstancesI18nDiModule
  private readonly installationInstancesDomainDiModule: InstallationInstancesDomainDiModule
  private readonly deviceTypesDomainCoreDiModule: DeviceTypesCoreDomainDiModule

  constructor(parameters: {
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly installationInstancesI18nDiModule: InstallationInstancesI18nDiModule
    readonly installationInstancesDomainDiModule: InstallationInstancesDomainDiModule
    readonly deviceTypesDomainCoreDiModule: DeviceTypesCoreDomainDiModule
  }) {
    super()
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.installationInstancesI18nDiModule = parameters.installationInstancesI18nDiModule
    this.installationInstancesDomainDiModule = parameters.installationInstancesDomainDiModule
    this.deviceTypesDomainCoreDiModule = parameters.deviceTypesDomainCoreDiModule
  }

  provideInstallationInstancesListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.installationInstancesDomainDiModule.provideGetInstallationInstancesUseCase(),
      tableProvider: this.provideInstallationInstancesTableProvider()
    })
  }

  private provideInstallationInstancesTableProvider(): InstallationInstancesTableProvider {
    return this.single(
      InstallationInstancesTableProvider.name,
      () => new InstallationInstancesTableProvider({
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        installationInstancesI18: this.installationInstancesI18nDiModule.provideInstallationInstancesI18(),
        getFuelCompaniesUseCase:
          this.installationInstancesDomainDiModule.provideGetFuelCompaniesForInstallationInstancesUseCase(),
        getGasStationsUseCase:
          this.installationInstancesDomainDiModule.provideGetGasStationsForInstallationInstanceUseCase()
      })
    )
  }

  provideInstallationInstanceFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.installationInstancesDomainDiModule.provideGetInstallationInstanceUseCase(),
      createObjectUseCase: this.installationInstancesDomainDiModule.provideCreateInstallationInstanceUseCase(),
      updateObjectUseCase: this.installationInstancesDomainDiModule.provideUpdateInstallationInstanceUseCase(),
      formProvider: this.provideInstallationInstanceFormProvider()
    })
  }

  private provideInstallationInstanceFormProvider(): InstallationInstanceFormProvider {
    return this.single(
      InstallationInstanceFormProvider.name,
      () => new InstallationInstanceFormProvider({
        installationInstancesI18: this.installationInstancesI18nDiModule.provideInstallationInstancesI18(),
        getGasStationsUseCase:
          this.installationInstancesDomainDiModule.provideGetGasStationsForInstallationInstanceUseCase(),
        getFuelCompaniesUseCase:
          this.installationInstancesDomainDiModule.provideGetFuelCompaniesForInstallationInstancesUseCase(),
        getDeviceTypesUseCase:
          this.deviceTypesDomainCoreDiModule.provideGetDeviceTypesUseCase()
      })
    )
  }

  provideInstallationInstanceInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.installationInstancesDomainDiModule.provideGetInstallationInstanceUseCase(),
      infoProvider: this.provideInstallationInstanceInfoProvider()
    })
  }

  private provideInstallationInstanceInfoProvider(): InstallationInstanceInfoProvider {
    return this.single(
      InstallationInstanceInfoProvider.name,
      () => new InstallationInstanceInfoProvider({
        installationInstanceI18: this.installationInstancesI18nDiModule.provideInstallationInstancesI18()
      })
    )
  }
}
